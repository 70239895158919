/**************menu part start*****************/
.home_menu {
	.menu_btn {
		&:hover a {
			color: $white-color !important;
		}
	}

	.main-menu-item {
		justify-content: center !important;
	}

	.main-menu-item {
		ul {
			li .nav-link {
				color: $primary_color;
			}
		}
	}
}

.main_menu {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	z-index: 999;

	.main-menu-item {
		justify-content: center;
	}

	.navbar-nav {
		margin-left: -140px;
	}

	.navbar-brand {
		padding-top: 0rem;
		padding-bottom: 0px;
	}

	.navbar {
		padding: 0px;
	}

	.main-menu-item {
		ul {
			li .nav-link {
				color: $menu_color;
				font-size: 14px;
				padding: 40px 20px;
				font-family: $font_stack_1;
				text-transform: uppercase;
				line-height: 12px;
				font-weight: 600;

				@media #{$medium_device} {
					padding: 35px 20px;
				}

				&:hover {
					color: $btn_bg;
				}
			}
		}
	}

	.menu_btn {
		border: 1px solid #dde0e3;
		color: $primary_color;
		font-size: 16px;
		padding: 9px 41px;
		border-radius: 50px;
		background-color: $white-color;

		&:hover {
			background-color: $primary_color;
			border: 1px solid $primary_color;
			color: $white-color;
		}
	}

	.single_page_logo {
		display: none;
	}
}


.dropdown {
	.dropdown-menu {
		transition: all 0.5s;
		overflow: hidden;
		transform-origin: top center;
		transform: scale(1, 0);
		display: block;
		border: 0px solid transparent;
		background-color: $primary_color;

		.dropdown-item {
			font-size: 14px;
			padding: 8px 20px !important;
			color: #fff !important;
			background-color: $primary_color;
			text-transform: capitalize;
		}

	}

	.dropdown-toggle::after {
		content: ' ';
	}

	&:hover {
		.dropdown-menu {
			transform: scale(1);
		}
	}

}



@media #{$tab} {
	.main_menu {
		padding: 20px 0px;
	}

	.navbar-nav {
		margin-left: 0 !important;
		text-align: center;

	}

	.main_logo {
		display: none;
	}

	.single_page_logo {
		display: block !important;
	}

	.home_menu .main-menu-item {
		padding-left: 0px;
	}

	.main_menu .main-menu-item {
		text-align: left !important;
		box-shadow: 0 15px 16px 0 rgba($color: #000000, $alpha: .1);
		// color: $black-color !important;

		.nav-item {
			padding: 10px 15px !important;

			.nav-link {
				padding: 5px 15px !important;
				color: $black_color;
			}
		}
	}

	.navbar-collapse {
		z-index: 9999 !important;
		position: absolute;
		left: 0;
		top: 50px;
		width: 100%;
		background-color: $white-color;
		text-align: center !important;
	}

	.dropdown {
		.dropdown-menu {
			transform: scale(1, 0);
			display: none;
			margin-top: 10px;
		}

		&:hover {
			.dropdown-menu {
				transform: scale(1);
				display: block;
				color: $primary_color;
			}
		}

		.dropdown-item:hover {
			color: $primary_color !important;
		}
	}

	.navbar-brand img {
		max-width: 100px;
		margin: 0 auto;
		position: absolute;
		left: 0;
		right: 0;
		top: 7px;
	}

	.navbar-light .navbar-toggler {
		border-color: transparent;
		position: absolute;
		left: 0;
		top: 0;
		width: 33px;
		height: 33px;
	}

	.btn_1 {
		margin-top: 0;
	}
}

@media #{$medium_device} {
	.main_menu .navbar-nav {
		margin-left: -50px;
	}

	.main_logo {
		display: none;
	}

	.single_page_logo {
		display: block !important;
	}

	.main-menu-item {
		ul {
			li .nav-link {
				color: #000 !important;

				&:hover {
					color: $btn_bg;
				}
			}
		}
	}
}

@media #{$medium_device} {
	.single_page_menu {
		ul {
			li .nav-link {
				color: #fff !important;

				&:hover {
					color: $btn_bg;
				}
			}
		}
	}
}

@media #{$small_mobile} {
	.navbar-brand img {
		max-width: 100px;
		right: auto;
		left: 0;
		top: 5px;
	}

	.navbar-light .navbar-toggler {
		border-color: transparent;
		position: absolute;
		right: 0;
		top: -2px;
		left: auto;
	}

	.main_menu {
		height: 75px;
	}

}

.dropdown-menu {
	border: 0px solid rgba(0, 0, 0, .15) !important;
	background-color: #fafafa;
}

.dropdown:hover {
	.dropdown-menu {
		display: block;
	}
}

.menu_icon {
	&:after {
		position: absolute;
		content: "🍔";
		top: 7px;
		left: 0px;
		font-family: 'themify';
		color: #000000;
		font-size: 25px;
		width: 20px;
		height: 20px;
		// background: linear-gradient(to bottom,
		// 		black, black 20%,
		// 		white 20%, white 40%,
		// 		black 40%, black 60%,
		// 		white 60%, white 80%,
		// 		black 80%, black 100%);
	}
}

.single_page_menu {
	.menu_icon {
		&:after {
			position: absolute;
			content: "\e68e";
			top: 7px;
			left: 0px;
			font-family: 'themify';
			color: $white-color;
			;
			font-size: 25px;
			width: 33px;
			height: 33px;
		}
	}
}

.single_page_menu {
	.main-menu-item {
		justify-content: center;

		.navbar-nav {
			margin-left: 0 !important;
		}
	}

	.btn_1 {
		background-color: $white-color;
		border: 1px solid $white-color;
		color: $heading_color;
		font-weight: 500;

		&:hover {
			background-color: $btn_bg;
			border: 1px solid $btn_bg;
		}
	}
}

.menu_fixed {
	position: fixed;
	z-index: 9999 !important;
	width: 100%;
	box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.05);
	top: 0;
	background-color: #562fc7;

	.navbar-nav {
		margin-left: 0;
	}

	.btn_1 {
		border: 1px solid #fff;
		color: $black_color;

		&:hover {
			border: 1px solid $btn_bg;
		}
	}

	.main-menu-item {
		ul {
			li .nav-link {
				color: #fff !important;
			}
		}
	}

	@media #{$small_mobile} {
		.main-menu-item {
			ul {
				li .nav-link {
					color: #000 !important;
				}
			}
		}
	}

	@media #{$large_mobile} {
		.main-menu-item {
			ul {
				li .nav-link {
					color: #000 !important;
				}
			}
		}
	}

	@media #{$tab_device} {
		.main-menu-item {
			ul {
				li .nav-link {
					color: #000 !important;
				}
			}
		}
	}

	.main_logo {
		display: block;
	}

	.single_page_logo {
		display: none !important;
	}

	.menu_icon:after {
		color: #fff;
	}

	.home_page_btn {
		color: $white-color;
	}
}