/************client css start***************/
.client_logo{
    padding: 0 0 140px;
    @media #{$tab}{
        padding: 0 0 70px;
    }
    @media #{$medium_device}{
        padding: 0 0 100px;
    }
    .single_client_logo{
        display: flex;
        align-items: center;
    }
    .owl-carousel .owl-item img {
        width: auto;
    }
    .single_client_logo{
        height: 100px;
        img{
            margin: 0 auto;
        }

    }
}