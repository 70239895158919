/* Main Button Area css
============================================================================================ */
.submit_btn{
	width: auto;
	display: inline-block;
	background: $white_color;
	padding: 0px 50px;
	color: #fff;
	font-size: 13px;
	font-weight: 500;
	line-height: 50px;
	border-radius: 5px;
	outline: none !important;
	box-shadow: none !important;
	text-align: center;
	border: 1px solid $border_color;
	cursor: pointer;
	@include transform_time(0.5s);
	&:hover{
		background: transparent;
		
	}
}
.btn_1{
	display: inline-block;
	padding: 13px 44px;
	border-radius: 50px;
	background-color: transparent;
	font-size: 15px;
	font-family: $font_stack_2;
	font-weight: 500;
	color: $heading_color;
	@include transform_time(0.5s);
	text-transform: uppercase;
	border: 1px solid $primary_color;

	&:hover{
		color: $white_color !important;
		background-color: $btn_bg;
	}
	@media #{$small_mobile}{
		padding: 10px 30px;
		margin-top: 20px;
	}
	@media #{$large_mobile}{
		padding: 10px 30px;
		margin-top: 20px;
	}
	@media #{$tab_device}{
		padding: 10px 30px;
		margin-top: 25px;
	}
	@media #{$medium_device}{
	
	}

}
.btn_2{
	display: inline-block;
	padding: 12px 44px;
	border-radius: 50px;
	background-color: $primary_color;
	font-size: 15px;
	font-family: $font_stack_2;
	font-weight: 500;
	color: $white_color;
	@include transform_time(0.5s);
	text-transform: uppercase;
	border: 2px solid $primary_color;
	&:hover{
		color: $primary_color !important;
		border: 2px solid $primary_color;
		background-color: transparent;
	}
	@media #{$small_mobile}{
		padding: 10px 30px;
		margin-top: 20px;
	}
	@media #{$large_mobile}{
		padding: 10px 30px;
		margin-top: 20px;
	}
	@media #{$tab_device}{
		padding: 10px 30px;
		margin-top: 25px;
	}
	@media #{$medium_device}{
	
	}
}
.btn_4{
	color: $btn_bg;
	font-size: 15px;
	font-weight: 700;
	margin-top: 53px;
	display: inline-block;
	@media #{$tab}{
		margin-top: 30px;
	}
	@media #{$medium_device}{
	
	}
	img{
		width: 20px;
		margin-left: 15px;
	}

}
/*=================== custom button rule start ====================*/

.button{
	display: inline-block;
	border: 1px solid transparent;
	font-size: 15px;
	font-weight: 500;
	padding: 12px 54px;
	border-radius: 4px;
	color: $white_color;
	border: 1px solid $border_color;
	text-transform: uppercase;
	background-color: $btn_bg;
	cursor: pointer;
	@include transform_time(0.5s);

	@media(max-width: 767px){
		font-size: 13px;
		padding: 9px 24px;
	}

	&:hover{
		color: $white_color;
	}


	&-link{
		letter-spacing: 0;
		color: #3b1d82;
		border: 0;
		padding: 0;

		&:hover{
			background: transparent;
			color: #3b1d82;
		}
	}

	&-header{
		color: $white_color;
		border-color: $border_color;

		&:hover{
			background: #b8024c;
			color: $white_color;
		}
	}

	&-contactForm{
		color: $white_color;
		border-color: $border_color;
		padding: 12px 25px;

		&:hover{
			// border-color: $title-color;
			// background: $title-color;
			// color: $white_color;
		}
	}
}


/* End Main Button Area css
============================================================================================ */