/************** accordion part start***************/
.accordion_part {
    button {
        font-size: 20px;
        color: #182028;
        font-weight: 500;
    }

    .card {
        margin-bottom: 30px;
        border-radius: 0;

        .card-header {
            background-color: transparent;
            border-bottom: 1px solid transparent;
            padding: 27px 16px;
        }

        .card-body {
            font-size: 16px;
            line-height: 28px;
            color: #888888;
            padding: 0px 30px 32px;
        }
    }

    h5 {
        position: relative;
        z-index: 1;

        button.btn.btn-link {
            &:after {
                position: absolute;
                content: "👇";
                top: 9px;
                right: 12px;
                height: auto;
                font-family: 'themify';
                color: $btn_bg;
            }
        }

        button.btn.btn-link.collapsed {
            &:after {
                content: "👇";
                color: #888888;
            }
        }
    }

    .btn-link:hover {
        color: #182028;
        text-decoration: none;
    }

    .btn {
        text-align: left !important;
    }
}