@mixin background($imgpath,$position: center,$size: cover,$repeat: no-repeat) {
    background: {
        image: url($imgpath);
        position: $position;
        repeat: $repeat;
        size: $size;
    }
}
@mixin transform_time($total_time) {
    -webkit-transition: $total_time;
    transition: $total_time;
}

@mixin gradient_color{
	background: linear-gradient(to right, #ff0000 0%, #c6019a 50%, #ff0000 100%);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	background-size: 200% auto;
	&:hover{
		background-position: center right;
	}
}

@mixin placeholder {
	&.placeholder {
		@content;
	}
	&:-moz-placeholder {
		@content;
	}
	&::-moz-placeholder {
		@content;
	}
	&::-webkit-input-placeholder {
		@content;
	}
}
@mixin transition($args: all 0.6s ease 0s) {
	-webkit-transition: $args;
	-moz-transition: $args;
	-o-transition: $args;
	transition: $args;
}

@mixin keyframes ($animation-name) {
	@-webkit-keyframes #{$animation-name} {
		@content;
	}
	@-moz-keyframes #{$animation-name} {
		@content;
	}
	@-o-keyframes #{$animation-name} {
		@content;
	}
	@keyframes #{$animation-name} {
		@content;
	}
}
