/**************** banner part css start ****************/
.banner_part {
    position: relative;
    background-image: url(https://i.ibb.co/5vWtrs7/banner-bg.png);
    background-repeat: no-repeat;
    background-size: 65% 100%;
    background-position: left top;

    @media #{$tab} {
        background-color: $light_white;
        background-image: none;
    }

    @media #{$medium_device} {
        background-color: $light_white;
        background-image: none;
    }

    .banner_text {
        display: table;
        width: 100%;
        height: 1020px;

        @media #{$big_screen} {
            margin-top: -20px;
        }

        @media #{$extra_big_screen} {
            margin-top: -20px;
        }

        .banner_text_iner {
            display: table-cell;
            vertical-align: middle;
            padding-top: 259px;

            @media #{$tab} {
                padding-top: 100px;
            }

            @media #{$medium_device} {
                height: 650px;
                padding-top: 0;
            }
        }

        @media #{$tab} {
            height: 650px;
        }

        @media #{$medium_device} {
            height: 650px;
        }

        h1 {
            font-size: 70px;
            text-transform: capitalize;
            font-weight: 700;
            margin-bottom: 40px;
            line-height: 70px;

            @media #{$small_mobile} {
                font-size: 25px;
                margin-bottom: 15px;
                line-height: 1.3;
            }

            @media #{$large_mobile} {
                font-size: 30px;
                margin-bottom: 15px;
                line-height: 1.3;
            }

            @media #{$tab_device} {
                font-size: 40px;
                margin-bottom: 15px;
                line-height: 1.3;
            }

            @media #{$medium_device} {
                font-size: 40px;
                margin-bottom: 15px;
                line-height: 1.4;
            }

            span {
                color: $primary_color;
            }
        }

        p {
            font-family: $font_stack_2;
            margin-bottom: 42px;
            padding-right: 110px;

            @media #{$tab} {
                padding-right: 0;
                margin-bottom: 10px;
            }

            @media #{$medium_device} {
                padding-right: 0;
            }
        }
    }

    .feature_icon_1 {
        position: absolute;
        right: 10%;
        top: 0%;
        z-index: -1;

        @media #{$tab} {
            display: none;
        }
    }

    .feature_icon_2 {
        position: absolute;
        right: 30%;
        top: 10%;
        z-index: -1;
    }

    .feature_icon_3 {
        position: absolute;
        right: 10%;
        top: 35%;
        z-index: -1;

        @media #{$tab} {
            display: none;
        }
    }

    .feature_icon_4 {
        position: absolute;
        right: 30%;
        top: 45%;
        z-index: -1;
    }

    .feature_icon_5 {
        position: absolute;
        right: 15%;
        bottom: 20%;
        z-index: -1;
    }

    .feature_icon_6 {
        position: absolute;
        right: 50%;
        bottom: 5%;
        z-index: -1;

        @media #{$tab} {
            display: none;
            bottom: 0%;
        }
    }
}

/**************** hero part css end ****************/