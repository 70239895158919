@charset "UTF-8";
/**************** extend css start ****************/
@import url("https://fonts.googleapis.com/css?family=Barlow+Semi+Condensed:300,400,500,600,700|Rajdhani:400,500,600,700&display=swap");
.form-contact .form-group .btn_1 {
  background-image: linear-gradient(to left, #fff 0%, #fff 51%, #fff 100%); }

/**************** extend css start ****************/
/**************** common css start ****************/
body {
  font-family: "Barlow Semi Condensed", sans-serif;
  padding: 0;
  margin: 0;
  font-size: 14px; }

.message_submit_form:focus {
  outline: none; }

input:hover, input:focus, .form-control:focus {
  outline: none !important;
  box-shadow: 0 0 0 0.2rem transparent;
  border: 1px solid #eaecee; }

.gray_bg {
  background-color: #f7f7f7; }

.section_padding {
  padding: 140px 0px; }
  @media only screen and (min-width: 992px) and (max-width: 1200px) {
    .section_padding {
      padding: 100px 0px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .section_padding {
      padding: 70px 0px; } }
  @media (max-width: 576px) {
    .section_padding {
      padding: 70px 0px; } }
  @media only screen and (min-width: 576px) and (max-width: 767px) {
    .section_padding {
      padding: 70px 0px; } }

.single_padding_top {
  padding-top: 140px !important; }
  @media only screen and (min-width: 992px) and (max-width: 1200px) {
    .single_padding_top {
      padding-top: 70px !important; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .single_padding_top {
      padding-top: 70px !important; } }
  @media (max-width: 576px) {
    .single_padding_top {
      padding-top: 70px !important; } }
  @media only screen and (min-width: 576px) and (max-width: 767px) {
    .single_padding_top {
      padding-top: 100px !important; } }

.padding_top {
  padding-top: 140px; }
  @media only screen and (min-width: 992px) and (max-width: 1200px) {
    .padding_top {
      padding-top: 100px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .padding_top {
      padding-top: 70px; } }
  @media (max-width: 576px) {
    .padding_top {
      padding-top: 70px; } }
  @media only screen and (min-width: 576px) and (max-width: 767px) {
    .padding_top {
      padding-top: 70px; } }

.padding_bottom {
  padding-bottom: 140px; }
  @media only screen and (min-width: 992px) and (max-width: 1200px) {
    .padding_bottom {
      padding-bottom: 100px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .padding_bottom {
      padding-bottom: 70px; } }
  @media (max-width: 576px) {
    .padding_bottom {
      padding-bottom: 70px; } }
  @media only screen and (min-width: 576px) and (max-width: 767px) {
    .padding_bottom {
      padding-bottom: 70px; } }

a {
  text-decoration: none;
  -webkit-transition: 0.5s;
  transition: 0.5s; }
  a:hover {
    outline: none;
    text-decoration: none; }

h1, h2, h3, h4, h5, h6 {
  color: #182028;
  font-family: "Barlow Semi Condensed", sans-serif; }

p {
  font-family: "Barlow Semi Condensed", sans-serif;
  line-height: 28px;
  font-size: 16px;
  margin-bottom: 0px;
  color: #888888;
  font-weight: 400; }

h2 {
  font-size: 44px;
  line-height: 28px;
  color: #182028;
  font-weight: 600;
  line-height: 1.222; }
  @media (max-width: 576px) {
    h2 {
      font-size: 22px;
      line-height: 25px; } }
  @media only screen and (min-width: 576px) and (max-width: 767px) {
    h2 {
      font-size: 24px;
      line-height: 25px; } }

h3 {
  font-size: 24px;
  line-height: 25px; }
  @media (max-width: 576px) {
    h3 {
      font-size: 20px; } }

h5 {
  font-size: 18px;
  line-height: 22px; }

img {
  max-width: 100%; }

a:focus, .button:focus, button:focus, .btn:focus {
  text-decoration: none;
  outline: none;
  box-shadow: none;
  -webkit-transition: 1s;
  transition: 1s; }

.section_tittle {
  margin-bottom: 53px; }
  @media (max-width: 991px) {
    .section_tittle {
      margin-bottom: 20px; } }
  @media only screen and (min-width: 992px) and (max-width: 1200px) {
    .section_tittle {
      margin-bottom: 40px; } }
  .section_tittle h2 {
    font-size: 45px;
    color: #182028;
    line-height: 33px;
    font-weight: 600;
    position: relative;
    margin-bottom: 28px; }
    @media (max-width: 576px) {
      .section_tittle h2 {
        font-size: 25px;
        line-height: 35px;
        margin-bottom: 15px; } }
    @media only screen and (min-width: 576px) and (max-width: 767px) {
      .section_tittle h2 {
        font-size: 25px;
        line-height: 35px;
        margin-bottom: 15px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .section_tittle h2 {
        font-size: 30px;
        line-height: 40px;
        margin-bottom: 15px; } }
    @media only screen and (min-width: 992px) and (max-width: 1200px) {
      .section_tittle h2 {
        font-size: 35px;
        line-height: 40px; } }
  .section_tittle p {
    color: #556172;
    text-transform: capitalize;
    line-height: 30px;
    font-family: "Rajdhani", sans-serif; }
    @media (max-width: 576px) {
      .section_tittle p {
        margin-bottom: 10px; } }
    @media only screen and (min-width: 576px) and (max-width: 767px) {
      .section_tittle p {
        margin-bottom: 10px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .section_tittle p {
        margin-bottom: 10px; } }
    @media only screen and (min-width: 992px) and (max-width: 1200px) {
      .section_tittle p {
        margin-bottom: 10px; } }

ul {
  list-style: none;
  margin: 0;
  padding: 0; }

.mb_110 {
  margin-bottom: 110px; }
  @media (max-width: 576px) {
    .mb_110 {
      margin-bottom: 220px; } }

.mt_130 {
  margin-top: 130px; }
  @media (max-width: 576px) {
    .mt_130 {
      margin-top: 70px; } }
  @media only screen and (min-width: 576px) and (max-width: 767px) {
    .mt_130 {
      margin-top: 70px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .mt_130 {
      margin-top: 70px; } }
  @media only screen and (min-width: 992px) and (max-width: 1200px) {
    .mt_130 {
      margin-top: 70px; } }

.mb_130 {
  margin-bottom: 140px; }
  @media (max-width: 991px) {
    .mb_130 {
      margin-bottom: 70px; } }
  @media only screen and (min-width: 992px) and (max-width: 1200px) {
    .mb_130 {
      margin-bottom: 100px; } }

.padding_less_40 {
  margin-bottom: -50px; }

.z_index {
  z-index: 9 !important;
  position: relative; }

@media only screen and (min-width: 1200px) and (max-width: 3640px) {
  .container {
    max-width: 1170px; } }

@media (max-width: 1200px) {
  [class*="hero-ani-"] {
    display: none !important; } }

.custom-animation1 {
  backface-visibility: hidden;
  animation: jumping 9s ease-in-out 2s infinite alternate;
  animation-delay: 1s;
  transition: all .9s ease 5s;
  user-select: none; }

.custom-animation2 {
  backface-visibility: hidden;
  animation: jumping2 9s ease-in-out 2s infinite alternate;
  animation-delay: 1s;
  transition: all 1s ease 3s;
  user-select: none; }

.custom-animation3 {
  backface-visibility: hidden;
  animation: jumping3 9s ease-in-out 2s infinite alternate;
  animation-delay: 1s;
  transition: all 7s ease 2s;
  user-select: none; }

.custom-animation4 {
  backface-visibility: hidden;
  animation: jumping4 9s ease-in-out 2s infinite alternate;
  animation-delay: 1s;
  transition: all 8s ease 4s;
  user-select: none; }

.custom-animation5 {
  backface-visibility: hidden;
  animation: jumping5 9s ease-in-out 2s infinite alternate;
  animation-delay: 1s;
  transition: all 8s ease 4s;
  user-select: none; }

.custom-animation6 {
  backface-visibility: hidden;
  animation: jumping6 9s ease-in-out 2s infinite alternate;
  animation-delay: 1s;
  transition: all 8s ease 4s;
  user-select: none; }

@keyframes jumping {
  0% {
    transform: translateY(0px) translateX(0) rotate(0) scale(1);
    opacity: .8; }
  25% {
    transform: translateY(-10px) translateX(-10px) rotate(20deg) scale(0.8);
    opacity: .9; }
  50% {
    transform: translateY(-15px) translateX(-15px) rotate(10deg) scale(0.9);
    opacity: .8; }
  75% {
    transform: translateY(-20px) translateX(-20px) rotate(20deg) scale(0.75);
    opacity: .6; }
  85% {
    transform: translateY(-25px) translateX(-25px) rotate(20deg) scale(0.9);
    opacity: .7; }
  100% {
    transform: translateY(-15px) translateX(-15px) rotate(0) scale(0.95);
    opacity: .85; } }

@keyframes jumping2 {
  0% {
    transform: translateY(0px) translateX(0) rotate(0) scale(1);
    opacity: .5; }
  25% {
    transform: translateY(-30px) translateX(10px) rotate(20deg) scale(0.8);
    opacity: .8; }
  50% {
    transform: translateY(15px) translateX(-15px) rotate(10deg) scale(0.7);
    opacity: .8; }
  75% {
    transform: translateY(30px) translateX(20px) rotate(90deg) scale(0.75);
    opacity: .7; }
  100% {
    transform: translateY(-15px) translateX(15px) rotate(0) scale(0.75);
    opacity: .9; } }

@keyframes jumping3 {
  0% {
    transform: translateY(10px) translateX(0) rotate(0) scale(1);
    opacity: .9; }
  20% {
    transform: translateY(20px) translateX(10px) rotate(-20deg) scale(0.8);
    opacity: .8; }
  40% {
    transform: translateY(15px) translateX(-15px) rotate(10deg) scale(0.75);
    opacity: .8; }
  40% {
    transform: translateY(-15px) translateX(-25px) rotate(10deg) scale(0.5);
    opacity: 1; }
  80% {
    transform: translateY(-30px) translateX(20px) rotate(-20deg) scale(0.75);
    opacity: .6; }
  100% {
    transform: translateY(15px) translateX(15px) rotate(0) scale(0.95);
    opacity: .7; } }

@keyframes jumping4 {
  0% {
    transform: translateY(-30px) translateX(40px) rotate(0) scale(1.2);
    opacity: .7; }
  25% {
    transform: translateY(-20px) translateX(0px) rotate(50deg) scale(0.6);
    opacity: .8; }
  50% {
    transform: translateY(15px) translateX(-55px) rotate(20deg) scale(0.5);
    opacity: .9; }
  75% {
    transform: translateY(30px) translateX(30px) rotate(50deg) scale(0.75);
    opacity: .7; }
  100% {
    transform: translateY(-15px) translateX(5px) rotate(0) scale(0.5);
    opacity: .9; } }

@keyframes jumping5 {
  0% {
    transform: rotate(180deg);
    display: block; }
  100% {
    transform: rotate(30deg);
    display: block; } }

@keyframes jumping6 {
  0% {
    transform: rotate(180deg) translate(-50px, 20px);
    display: block; }
  100% {
    transform: rotate(30deg) translate(0px, 0px);
    display: block; } }

/**************** common css end ****************/
/* Main Button Area css
============================================================================================ */
.submit_btn {
  width: auto;
  display: inline-block;
  background: #fff;
  padding: 0px 50px;
  color: #fff;
  font-size: 13px;
  font-weight: 500;
  line-height: 50px;
  border-radius: 5px;
  outline: none !important;
  box-shadow: none !important;
  text-align: center;
  border: 1px solid #edeff2;
  cursor: pointer;
  -webkit-transition: 0.5s;
  transition: 0.5s; }
  .submit_btn:hover {
    background: transparent; }

.btn_1 {
  display: inline-block;
  padding: 13px 44px;
  border-radius: 50px;
  background-color: transparent;
  font-size: 15px;
  font-family: "Rajdhani", sans-serif;
  font-weight: 500;
  color: #182028;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  text-transform: uppercase;
  border: 1px solid #ff4800; }
  .btn_1:hover {
    color: #fff !important;
    background-color: #ff4800; }
  @media (max-width: 576px) {
    .btn_1 {
      padding: 10px 30px;
      margin-top: 20px; } }
  @media only screen and (min-width: 576px) and (max-width: 767px) {
    .btn_1 {
      padding: 10px 30px;
      margin-top: 20px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .btn_1 {
      padding: 10px 30px;
      margin-top: 25px; } }

.btn_2 {
  display: inline-block;
  padding: 12px 44px;
  border-radius: 50px;
  background-color: #ff4800;
  font-size: 15px;
  font-family: "Rajdhani", sans-serif;
  font-weight: 500;
  color: #fff;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  text-transform: uppercase;
  border: 2px solid #ff4800; }
  .btn_2:hover {
    color: #ff4800 !important;
    border: 2px solid #ff4800;
    background-color: transparent; }
  @media (max-width: 576px) {
    .btn_2 {
      padding: 10px 30px;
      margin-top: 20px; } }
  @media only screen and (min-width: 576px) and (max-width: 767px) {
    .btn_2 {
      padding: 10px 30px;
      margin-top: 20px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .btn_2 {
      padding: 10px 30px;
      margin-top: 25px; } }

.btn_4 {
  color: #ff4800;
  font-size: 15px;
  font-weight: 700;
  margin-top: 53px;
  display: inline-block; }
  @media (max-width: 991px) {
    .btn_4 {
      margin-top: 30px; } }
  .btn_4 img {
    width: 20px;
    margin-left: 15px; }

/*=================== custom button rule start ====================*/
.button {
  display: inline-block;
  border: 1px solid transparent;
  font-size: 15px;
  font-weight: 500;
  padding: 12px 54px;
  border-radius: 4px;
  color: #fff;
  border: 1px solid #edeff2;
  text-transform: uppercase;
  background-color: #ff4800;
  cursor: pointer;
  -webkit-transition: 0.5s;
  transition: 0.5s; }
  @media (max-width: 767px) {
    .button {
      font-size: 13px;
      padding: 9px 24px; } }
  .button:hover {
    color: #fff; }
  .button-link {
    letter-spacing: 0;
    color: #3b1d82;
    border: 0;
    padding: 0; }
    .button-link:hover {
      background: transparent;
      color: #3b1d82; }
  .button-header {
    color: #fff;
    border-color: #edeff2; }
    .button-header:hover {
      background: #b8024c;
      color: #fff; }
  .button-contactForm {
    color: #fff;
    border-color: #edeff2;
    padding: 12px 25px; }

/* End Main Button Area css
============================================================================================ */
/* Start Blog Area css
============================================================================================ */
.latest-blog-area .area-heading {
  margin-bottom: 70px; }

.blog_area a {
  color: #666666 !important;
  text-decoration: none;
  -webkit-transition: 0.5s;
  transition: 0.5s; }
  .blog_area a:hover, .blog_area a :hover {
    color: #ff4800; }

.single-blog {
  overflow: hidden;
  margin-bottom: 30px; }
  .single-blog:hover {
    box-shadow: 0px 10px 20px 0px rgba(42, 34, 123, 0.1); }
  .single-blog .thumb {
    overflow: hidden;
    position: relative; }
    .single-blog .thumb:after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: #000;
      opacity: 0;
      -webkit-transition: 0.5s;
      transition: 0.5s; }
  .single-blog h4 {
    border-bottom: 1px solid #dfdfdf;
    padding-bottom: 34px;
    margin-bottom: 25px; }
  .single-blog a {
    font-size: 20px;
    font-weight: 600; }
  .single-blog .date {
    color: #888;
    text-align: left;
    display: inline-block;
    font-size: 13px;
    font-weight: 300; }
  .single-blog .tag {
    text-align: left;
    display: inline-block;
    float: left;
    font-size: 13px;
    font-weight: 300;
    margin-right: 22px;
    position: relative; }
    .single-blog .tag:after {
      content: '';
      position: absolute;
      width: 1px;
      height: 10px;
      background: #acacac;
      right: -12px;
      top: 7px; }
    @media (max-width: 1199px) {
      .single-blog .tag {
        margin-right: 8px; }
        .single-blog .tag:after {
          display: none; } }
  .single-blog .likes {
    margin-right: 16px; }
  @media (max-width: 800px) {
    .single-blog {
      margin-bottom: 30px; } }
  .single-blog .single-blog-content {
    padding: 30px; }
    .single-blog .single-blog-content .meta-bottom p {
      font-size: 13px;
      font-weight: 300; }
    .single-blog .single-blog-content .meta-bottom i {
      color: #edeff2;
      font-size: 13px;
      margin-right: 7px; }
    @media (max-width: 1199px) {
      .single-blog .single-blog-content {
        padding: 15px; } }
  .single-blog:hover .thumb:after {
    opacity: .7;
    -webkit-transition: 0.5s;
    transition: 0.5s; }
  @media (max-width: 1199px) {
    .single-blog h4 {
      transition: all 300ms linear 0s;
      border-bottom: 1px solid #dfdfdf;
      padding-bottom: 14px;
      margin-bottom: 12px; }
      .single-blog h4 a {
        font-size: 18px; } }

.full_image.single-blog {
  position: relative; }
  .full_image.single-blog .single-blog-content {
    position: absolute;
    left: 35px;
    bottom: 0;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: 0.5s;
    transition: 0.5s; }
    @media (min-width: 992px) {
      .full_image.single-blog .single-blog-content {
        bottom: 100px; } }
  .full_image.single-blog h4 {
    -webkit-transition: 0.5s;
    transition: 0.5s;
    border-bottom: none;
    padding-bottom: 5px; }
  .full_image.single-blog a {
    font-size: 20px;
    font-weight: 600; }
  .full_image.single-blog .date {
    color: #fff; }
  .full_image.single-blog:hover .single-blog-content {
    opacity: 1;
    visibility: visible;
    -webkit-transition: 0.5s;
    transition: 0.5s; }

/* End Blog Area css
============================================================================================ */
/* Latest Blog Area css
============================================================================================ */
.l_blog_item .l_blog_text .date {
  margin-top: 24px;
  margin-bottom: 15px; }
  .l_blog_item .l_blog_text .date a {
    font-size: 12px; }

.l_blog_item .l_blog_text h4 {
  font-size: 18px;
  border-bottom: 1px solid #eeeeee;
  margin-bottom: 0px;
  padding-bottom: 20px;
  -webkit-transition: 0.5s;
  transition: 0.5s; }

.l_blog_item .l_blog_text p {
  margin-bottom: 0px;
  padding-top: 20px; }

/* End Latest Blog Area css
============================================================================================ */
/* Causes Area css
============================================================================================ */
.causes_slider .owl-dots {
  text-align: center;
  margin-top: 80px; }
  .causes_slider .owl-dots .owl-dot {
    height: 14px;
    width: 14px;
    background: #eeeeee;
    display: inline-block;
    margin-right: 7px; }
    .causes_slider .owl-dots .owl-dot:last-child {
      margin-right: 0px; }

.causes_item {
  background: #fff; }
  .causes_item .causes_img {
    position: relative; }
    .causes_item .causes_img .c_parcent {
      position: absolute;
      bottom: 0px;
      width: 100%;
      left: 0px;
      height: 3px;
      background: rgba(255, 255, 255, 0.5); }
      .causes_item .causes_img .c_parcent span {
        width: 70%;
        height: 3px;
        position: absolute;
        left: 0px;
        bottom: 0px; }
        .causes_item .causes_img .c_parcent span:before {
          content: "75%";
          position: absolute;
          right: -10px;
          bottom: 0px;
          color: #fff;
          padding: 0px 5px; }
  .causes_item .causes_text {
    padding: 30px 35px 40px 30px; }
    .causes_item .causes_text h4 {
      font-size: 18px;
      font-weight: 600;
      margin-bottom: 15px;
      cursor: pointer; }
    .causes_item .causes_text p {
      font-size: 14px;
      line-height: 24px;
      font-weight: 300;
      margin-bottom: 0px; }
  .causes_item .causes_bottom a {
    width: 50%;
    border: 1px solid;
    text-align: center;
    float: left;
    line-height: 50px;
    color: #fff;
    font-size: 14px;
    font-weight: 500; }
    .causes_item .causes_bottom a + a {
      border-color: #eeeeee;
      background: #fff;
      font-size: 14px; }

/* End Causes Area css
============================================================================================ */
/*================= latest_blog_area css =============*/
.latest_blog_area {
  background: #f9f9ff; }

.single-recent-blog-post {
  margin-bottom: 30px; }
  .single-recent-blog-post .thumb {
    overflow: hidden; }
    .single-recent-blog-post .thumb img {
      transition: all 0.7s linear; }
  .single-recent-blog-post .details {
    padding-top: 30px; }
    .single-recent-blog-post .details .sec_h4 {
      line-height: 24px;
      padding: 10px 0px 13px;
      transition: all 0.3s linear; }
  .single-recent-blog-post .date {
    font-size: 14px;
    line-height: 24px;
    font-weight: 400; }
  .single-recent-blog-post:hover img {
    transform: scale(1.23) rotate(10deg); }

.tags .tag_btn {
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  border: 1px solid #eeeeee;
  display: inline-block;
  padding: 1px 18px;
  text-align: center; }
  .tags .tag_btn + .tag_btn {
    margin-left: 2px; }

/*========= blog_categorie_area css ===========*/
.blog_categorie_area {
  padding-top: 30px;
  padding-bottom: 30px; }
  @media (min-width: 900px) {
    .blog_categorie_area {
      padding-top: 80px;
      padding-bottom: 80px; } }
  @media (min-width: 1100px) {
    .blog_categorie_area {
      padding-top: 120px;
      padding-bottom: 120px; } }

.categories_post {
  position: relative;
  text-align: center;
  cursor: pointer; }
  .categories_post img {
    max-width: 100%; }
  .categories_post .categories_details {
    position: absolute;
    top: 20px;
    left: 20px;
    right: 20px;
    bottom: 20px;
    background: rgba(34, 34, 34, 0.75);
    color: #fff;
    transition: all 0.3s linear;
    display: flex;
    align-items: center;
    justify-content: center; }
    .categories_post .categories_details h5 {
      margin-bottom: 0px;
      font-size: 18px;
      line-height: 26px;
      text-transform: uppercase;
      color: #fff;
      position: relative; }
    .categories_post .categories_details p {
      font-weight: 300;
      font-size: 14px;
      line-height: 26px;
      margin-bottom: 0px; }
    .categories_post .categories_details .border_line {
      margin: 10px 0px;
      background: #fff;
      width: 100%;
      height: 1px; }
  .categories_post:hover .categories_details {
    background: rgba(222, 99, 32, 0.85); }

/*============ blog_left_sidebar css ==============*/
.blog_item {
  margin-bottom: 50px; }

.blog_details {
  padding: 30px 0 20px 10px;
  box-shadow: 0px 10px 20px 0px rgba(221, 221, 221, 0.3); }
  @media (min-width: 768px) {
    .blog_details {
      padding: 60px 30px 35px 35px; } }
  .blog_details p {
    margin-bottom: 30px; }
  .blog_details a {
    color: #ff8b23; }
    .blog_details a:hover {
      color: #ff4800 !important; }
  .blog_details h2 {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 8px; }
    @media (min-width: 768px) {
      .blog_details h2 {
        font-size: 24px;
        margin-bottom: 15px; } }

.blog-info-link li {
  float: left;
  font-size: 14px; }
  .blog-info-link li a {
    color: #999999; }
  .blog-info-link li i,
  .blog-info-link li span {
    font-size: 13px;
    margin-right: 5px; }
  .blog-info-link li::after {
    content: "|";
    padding-left: 10px;
    padding-right: 10px; }
  .blog-info-link li:last-child::after {
    display: none; }

.blog-info-link::after {
  content: "";
  display: block;
  clear: both;
  display: table; }

.blog_item_img {
  position: relative; }
  .blog_item_img .blog_item_date {
    position: absolute;
    bottom: -10px;
    left: 10px;
    display: block;
    color: #fff;
    background-color: #ff4800;
    padding: 8px 15px;
    border-radius: 5px; }
    @media (min-width: 768px) {
      .blog_item_img .blog_item_date {
        bottom: -20px;
        left: 40px;
        padding: 13px 30px; } }
    .blog_item_img .blog_item_date h3 {
      font-size: 22px;
      font-weight: 600;
      color: #fff;
      margin-bottom: 0;
      line-height: 1.2; }
      @media (min-width: 768px) {
        .blog_item_img .blog_item_date h3 {
          font-size: 30px; } }
    .blog_item_img .blog_item_date p {
      font-size: 18px;
      margin-bottom: 0;
      color: #fff; }
      @media (min-width: 768px) {
        .blog_item_img .blog_item_date p {
          font-size: 18px; } }

.blog_right_sidebar .widget_title {
  font-size: 20px;
  margin-bottom: 40px; }
  .blog_right_sidebar .widget_title::after {
    content: "";
    display: block;
    padding-top: 15px;
    border-bottom: 1px solid #f0e9ff; }

.blog_right_sidebar .single_sidebar_widget {
  background: #fbf9ff;
  padding: 30px;
  margin-bottom: 30px; }

.blog_right_sidebar .search_widget .form-control {
  height: 50px;
  border-color: #f0e9ff;
  font-size: 13px;
  color: #999999;
  padding-left: 20px;
  border-radius: 0;
  border-right: 0; }
  .blog_right_sidebar .search_widget .form-control::placeholder {
    color: #999999; }
  .blog_right_sidebar .search_widget .form-control:focus {
    border-color: #f0e9ff;
    outline: 0;
    box-shadow: none; }

.blog_right_sidebar .search_widget .input-group button {
  background: #fff;
  border-left: 0;
  border: 1px solid #f0e9ff;
  padding: 4px 15px;
  border-left: 0; }
  .blog_right_sidebar .search_widget .input-group button i,
  .blog_right_sidebar .search_widget .input-group button span {
    font-size: 14px;
    color: #999999; }

.blog_right_sidebar .newsletter_widget .form-control {
  height: 50px;
  border-color: #f0e9ff;
  font-size: 13px;
  color: #999999;
  padding-left: 20px;
  border-radius: 0; }
  .blog_right_sidebar .newsletter_widget .form-control::placeholder {
    color: #999999; }
  .blog_right_sidebar .newsletter_widget .form-control:focus {
    border-color: #f0e9ff;
    outline: 0;
    box-shadow: none; }

.blog_right_sidebar .newsletter_widget .input-group button {
  background: #fff;
  border-left: 0;
  border: 1px solid #f0e9ff;
  padding: 4px 15px;
  border-left: 0; }
  .blog_right_sidebar .newsletter_widget .input-group button i,
  .blog_right_sidebar .newsletter_widget .input-group button span {
    font-size: 14px;
    color: #999999; }

.blog_right_sidebar .post_category_widget .cat-list li {
  border-bottom: 1px solid #f0e9ff;
  transition: all 0.3s ease 0s;
  padding-bottom: 12px; }
  .blog_right_sidebar .post_category_widget .cat-list li:last-child {
    border-bottom: 0; }
  .blog_right_sidebar .post_category_widget .cat-list li a {
    font-size: 14px;
    line-height: 20px;
    color: #888888; }
    .blog_right_sidebar .post_category_widget .cat-list li a p {
      margin-bottom: 0px; }
  .blog_right_sidebar .post_category_widget .cat-list li + li {
    padding-top: 15px; }

.blog_right_sidebar .popular_post_widget .post_item .media-body {
  justify-content: center;
  align-self: center;
  padding-left: 20px; }
  .blog_right_sidebar .popular_post_widget .post_item .media-body h3 {
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 6px;
    transition: all 0.3s linear; }
  .blog_right_sidebar .popular_post_widget .post_item .media-body a:hover {
    color: #fff; }
  .blog_right_sidebar .popular_post_widget .post_item .media-body p {
    font-size: 14px;
    line-height: 21px;
    margin-bottom: 0px; }

.blog_right_sidebar .popular_post_widget .post_item + .post_item {
  margin-top: 20px; }

.blog_right_sidebar .tag_cloud_widget ul li {
  display: inline-block; }
  .blog_right_sidebar .tag_cloud_widget ul li a {
    display: inline-block;
    border: 1px solid #eeeeee;
    background: #fff;
    padding: 4px 20px;
    margin-bottom: 8px;
    margin-right: 3px;
    transition: all 0.3s ease 0s;
    color: #888888;
    font-size: 13px; }
    .blog_right_sidebar .tag_cloud_widget ul li a:hover {
      background: #ff4800;
      color: #fff !important;
      -webkit-text-fill-color: #fff;
      text-decoration: none;
      -webkit-transition: 0.5s;
      transition: 0.5s; }

.blog_right_sidebar .instagram_feeds .instagram_row {
  display: flex;
  margin-right: -6px;
  margin-left: -6px; }
  .blog_right_sidebar .instagram_feeds .instagram_row li {
    width: 33.33%;
    float: left;
    padding-right: 6px;
    padding-left: 6px;
    margin-bottom: 15px; }

.blog_right_sidebar .br {
  width: 100%;
  height: 1px;
  background: #eeeeee;
  margin: 30px 0px; }

.blog-pagination {
  margin-top: 80px; }

.blog-pagination .page-link {
  font-size: 14px;
  position: relative;
  display: block;
  padding: 0;
  text-align: center;
  margin-left: -1px;
  line-height: 45px;
  width: 45px;
  height: 45px;
  border-radius: 0 !important;
  color: #8a8a8a;
  border: 1px solid #f0e9ff;
  margin-right: 10px; }
  .blog-pagination .page-link i,
  .blog-pagination .page-link span {
    font-size: 13px; }

.blog-pagination .page-item.active .page-link {
  background-color: #fbf9ff;
  border-color: #f0e9ff;
  color: #888888; }

.blog-pagination .page-item:last-child .page-link {
  margin-right: 0; }

/*============ Start Blog Single Styles  =============*/
.single-post-area .blog_details {
  box-shadow: none;
  padding: 0; }

.single-post-area .social-links {
  padding-top: 10px; }
  .single-post-area .social-links li {
    display: inline-block;
    margin-bottom: 10px; }
    .single-post-area .social-links li a {
      color: #cccccc;
      padding: 7px;
      font-size: 14px;
      transition: all 0.2s linear; }

.single-post-area .blog_details {
  padding-top: 26px; }
  .single-post-area .blog_details p {
    margin-bottom: 20px;
    font-size: 15px; }

.single-post-area .quote-wrapper {
  background: rgba(130, 139, 178, 0.1);
  padding: 15px;
  line-height: 1.733;
  color: #888888;
  font-style: italic;
  margin-top: 25px;
  margin-bottom: 25px; }
  @media (min-width: 768px) {
    .single-post-area .quote-wrapper {
      padding: 30px; } }

.single-post-area .quotes {
  background: #fff;
  padding: 15px 15px 15px 20px;
  border-left: 2px solid; }
  @media (min-width: 768px) {
    .single-post-area .quotes {
      padding: 25px 25px 25px 30px; } }

.single-post-area .arrow {
  position: absolute; }
  .single-post-area .arrow .lnr {
    font-size: 20px;
    font-weight: 600; }

.single-post-area .thumb .overlay-bg {
  background: rgba(0, 0, 0, 0.8); }

.single-post-area .navigation-top {
  padding-top: 15px;
  border-top: 1px solid #f0e9ff; }
  .single-post-area .navigation-top p {
    margin-bottom: 0; }
  .single-post-area .navigation-top .like-info {
    font-size: 14px; }
    .single-post-area .navigation-top .like-info i,
    .single-post-area .navigation-top .like-info span {
      font-size: 16px;
      margin-right: 5px; }
  .single-post-area .navigation-top .comment-count {
    font-size: 14px; }
    .single-post-area .navigation-top .comment-count i,
    .single-post-area .navigation-top .comment-count span {
      font-size: 16px;
      margin-right: 5px; }
  .single-post-area .navigation-top .social-icons li {
    display: inline-block;
    margin-right: 15px; }
    .single-post-area .navigation-top .social-icons li:last-child {
      margin: 0; }
    .single-post-area .navigation-top .social-icons li i,
    .single-post-area .navigation-top .social-icons li span {
      font-size: 14px;
      color: #999999; }

.single-post-area .blog-author {
  padding: 40px 30px;
  background: #fbf9ff;
  margin-top: 50px; }
  @media (max-width: 600px) {
    .single-post-area .blog-author {
      padding: 20px 8px; } }
  .single-post-area .blog-author img {
    width: 90px;
    height: 90px;
    border-radius: 50%;
    margin-right: 30px; }
    @media (max-width: 600px) {
      .single-post-area .blog-author img {
        margin-right: 15px;
        width: 45px;
        height: 45px; } }
  .single-post-area .blog-author a {
    display: inline-block; }
    .single-post-area .blog-author a:hover {
      color: #ff4800; }
  .single-post-area .blog-author p {
    margin-bottom: 0;
    font-size: 15px; }
  .single-post-area .blog-author h4 {
    font-size: 16px; }

.single-post-area .navigation-area {
  border-bottom: 1px solid #eee;
  padding-bottom: 30px;
  margin-top: 55px; }
  .single-post-area .navigation-area p {
    margin-bottom: 0px; }
  .single-post-area .navigation-area h4 {
    font-size: 18px;
    line-height: 25px; }
  .single-post-area .navigation-area .nav-left {
    text-align: left; }
    .single-post-area .navigation-area .nav-left .thumb {
      margin-right: 20px;
      background: #000; }
      .single-post-area .navigation-area .nav-left .thumb img {
        -webkit-transition: 0.5s;
        transition: 0.5s; }
    .single-post-area .navigation-area .nav-left .lnr {
      margin-left: 20px;
      opacity: 0;
      -webkit-transition: 0.5s;
      transition: 0.5s; }
    .single-post-area .navigation-area .nav-left:hover .lnr {
      opacity: 1; }
    .single-post-area .navigation-area .nav-left:hover .thumb img {
      opacity: .5; }
    @media (max-width: 767px) {
      .single-post-area .navigation-area .nav-left {
        margin-bottom: 30px; } }
  .single-post-area .navigation-area .nav-right {
    text-align: right; }
    .single-post-area .navigation-area .nav-right .thumb {
      margin-left: 20px;
      background: #000; }
      .single-post-area .navigation-area .nav-right .thumb img {
        -webkit-transition: 0.5s;
        transition: 0.5s; }
    .single-post-area .navigation-area .nav-right .lnr {
      margin-right: 20px;
      opacity: 0;
      -webkit-transition: 0.5s;
      transition: 0.5s; }
    .single-post-area .navigation-area .nav-right:hover .lnr {
      opacity: 1; }
    .single-post-area .navigation-area .nav-right:hover .thumb img {
      opacity: .5; }

@media (max-width: 991px) {
  .single-post-area .sidebar-widgets {
    padding-bottom: 0px; } }

.comments-area {
  background: transparent;
  border-top: 1px solid #eee;
  padding: 45px 0;
  margin-top: 50px; }
  @media (max-width: 414px) {
    .comments-area {
      padding: 50px 8px; } }
  .comments-area h4 {
    margin-bottom: 35px;
    font-size: 18px; }
  .comments-area h5 {
    font-size: 16px;
    margin-bottom: 0px; }
  .comments-area .comment-list {
    padding-bottom: 48px; }
    .comments-area .comment-list:last-child {
      padding-bottom: 0px; }
    .comments-area .comment-list.left-padding {
      padding-left: 25px; }
    @media (max-width: 413px) {
      .comments-area .comment-list .single-comment h5 {
        font-size: 12px; }
      .comments-area .comment-list .single-comment .date {
        font-size: 11px; }
      .comments-area .comment-list .single-comment .comment {
        font-size: 10px; } }
  .comments-area .thumb {
    margin-right: 20px; }
    .comments-area .thumb img {
      width: 70px;
      border-radius: 50%; }
  .comments-area .date {
    font-size: 14px;
    color: #999999;
    margin-bottom: 0;
    margin-left: 20px; }
  .comments-area .comment {
    margin-bottom: 10px;
    color: #777777;
    font-size: 15px; }
  .comments-area .btn-reply {
    background-color: transparent;
    color: #888888;
    padding: 5px 18px;
    font-size: 14px;
    display: block;
    font-weight: 400; }

.comment-form {
  border-top: 1px solid #eee;
  padding-top: 45px;
  margin-top: 50px;
  margin-bottom: 20px; }
  .comment-form .form-group {
    margin-bottom: 30px; }
  .comment-form h4 {
    margin-bottom: 40px;
    font-size: 18px;
    line-height: 22px; }
  .comment-form .name {
    padding-left: 0px; }
    @media (max-width: 767px) {
      .comment-form .name {
        padding-right: 0px;
        margin-bottom: 1rem; } }
  .comment-form .email {
    padding-right: 0px; }
    @media (max-width: 991px) {
      .comment-form .email {
        padding-left: 0px; } }
  .comment-form .form-control {
    border: 1px solid #f0e9ff;
    border-radius: 5px;
    height: 48px;
    padding-left: 18px;
    font-size: 13px;
    background: transparent; }
    .comment-form .form-control:focus {
      outline: 0;
      box-shadow: none; }
    .comment-form .form-control::placeholder {
      font-weight: 300;
      color: #999999; }
    .comment-form .form-control::placeholder {
      color: #777777; }
  .comment-form textarea {
    padding-top: 18px;
    border-radius: 12px;
    height: 100% !important; }
  .comment-form ::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    font-size: 13px;
    color: #777; }
  .comment-form ::-moz-placeholder {
    /* Firefox 19+ */
    font-size: 13px;
    color: #777; }
  .comment-form :-ms-input-placeholder {
    /* IE 10+ */
    font-size: 13px;
    color: #777; }
  .comment-form :-moz-placeholder {
    /* Firefox 18- */
    font-size: 13px;
    color: #777; }

.single_sidebar_widget .btn_1 {
  margin-top: 0px;
  padding: 15px 24px; }

/*============ End Blog Single Styles  =============*/
.single_blog_post .desc a {
  font-size: 16px;
  color: #232b2b !important; }

.single_blog_post .single_blog .single_appartment_content {
  padding: 38px 38px 23px;
  border: 0px solid #edeff2;
  box-shadow: 0px 10px 20px 0px rgba(221, 221, 221, 0.3); }
  .single_blog_post .single_blog .single_appartment_content p {
    font-size: 12px;
    text-transform: uppercase;
    margin-bottom: 20px; }
    .single_blog_post .single_blog .single_appartment_content p a {
      color: #ff4800; }
  .single_blog_post .single_blog .single_appartment_content h4 {
    font-size: 24px;
    font-weight: 600;
    line-height: 1.481;
    margin-bottom: 16px; }
  .single_blog_post .single_blog .single_appartment_content h5 {
    font-size: 15px;
    color: #8a8a8a;
    font-weight: 400; }
  .single_blog_post .single_blog .single_appartment_content .list-unstyled {
    margin-top: 33px; }
    .single_blog_post .single_blog .single_appartment_content .list-unstyled li {
      display: inline;
      margin-right: 17px;
      color: #999999; }
      .single_blog_post .single_blog .single_appartment_content .list-unstyled li a {
        margin-right: 8px;
        color: #999999; }

/**************menu part start*****************/
.home_menu .menu_btn:hover a {
  color: #fff !important; }

.home_menu .main-menu-item {
  justify-content: center !important; }

.home_menu .main-menu-item ul li .nav-link {
  color: #ff4800; }

.main_menu {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 999; }
  .main_menu .main-menu-item {
    justify-content: center; }
  .main_menu .navbar-nav {
    margin-left: -140px; }
  .main_menu .navbar-brand {
    padding-top: 0rem;
    padding-bottom: 0px; }
  .main_menu .navbar {
    padding: 0px; }
  .main_menu .main-menu-item ul li .nav-link {
    color: #ffffff;
    font-size: 14px;
    padding: 40px 20px;
    font-family: "Barlow Semi Condensed", sans-serif;
    text-transform: uppercase;
    line-height: 12px;
    font-weight: 600; }
    @media only screen and (min-width: 992px) and (max-width: 1200px) {
      .main_menu .main-menu-item ul li .nav-link {
        padding: 35px 20px; } }
    .main_menu .main-menu-item ul li .nav-link:hover {
      color: #ff4800; }
  .main_menu .menu_btn {
    border: 1px solid #dde0e3;
    color: #ff4800;
    font-size: 16px;
    padding: 9px 41px;
    border-radius: 50px;
    background-color: #fff; }
    .main_menu .menu_btn:hover {
      background-color: #ff4800;
      border: 1px solid #ff4800;
      color: #fff; }
  .main_menu .single_page_logo {
    display: none; }

.dropdown .dropdown-menu {
  transition: all 0.5s;
  overflow: hidden;
  transform-origin: top center;
  transform: scale(1, 0);
  display: block;
  border: 0px solid transparent;
  background-color: #ff4800; }
  .dropdown .dropdown-menu .dropdown-item {
    font-size: 14px;
    padding: 8px 20px !important;
    color: #fff !important;
    background-color: #ff4800;
    text-transform: capitalize; }

.dropdown .dropdown-toggle::after {
  content: ' '; }

.dropdown:hover .dropdown-menu {
  transform: scale(1); }

@media (max-width: 991px) {
  .main_menu {
    padding: 20px 0px; }
  .navbar-nav {
    margin-left: 0 !important;
    text-align: center; }
  .main_logo {
    display: none; }
  .single_page_logo {
    display: block !important; }
  .home_menu .main-menu-item {
    padding-left: 0px; }
  .main_menu .main-menu-item {
    text-align: left !important;
    box-shadow: 0 15px 16px 0 rgba(0, 0, 0, 0.1); }
    .main_menu .main-menu-item .nav-item {
      padding: 10px 15px !important; }
      .main_menu .main-menu-item .nav-item .nav-link {
        padding: 5px 15px !important;
        color: #000; }
  .navbar-collapse {
    z-index: 9999 !important;
    position: absolute;
    left: 0;
    top: 50px;
    width: 100%;
    background-color: #fff;
    text-align: center !important; }
  .dropdown .dropdown-menu {
    transform: scale(1, 0);
    display: none;
    margin-top: 10px; }
  .dropdown:hover .dropdown-menu {
    transform: scale(1);
    display: block;
    color: #ff4800; }
  .dropdown .dropdown-item:hover {
    color: #ff4800 !important; }
  .navbar-brand img {
    max-width: 100px;
    margin: 0 auto;
    position: absolute;
    left: 0;
    right: 0;
    top: 7px; }
  .navbar-light .navbar-toggler {
    border-color: transparent;
    position: absolute;
    left: 0;
    top: 0;
    width: 33px;
    height: 33px; }
  .btn_1 {
    margin-top: 0; } }

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .main_menu .navbar-nav {
    margin-left: -50px; }
  .main_logo {
    display: none; }
  .single_page_logo {
    display: block !important; }
  .main-menu-item ul li .nav-link {
    color: #000 !important; }
    .main-menu-item ul li .nav-link:hover {
      color: #ff4800; } }

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .single_page_menu ul li .nav-link {
    color: #fff !important; }
    .single_page_menu ul li .nav-link:hover {
      color: #ff4800; } }

@media (max-width: 576px) {
  .navbar-brand img {
    max-width: 100px;
    right: auto;
    left: 0;
    top: 5px; }
  .navbar-light .navbar-toggler {
    border-color: transparent;
    position: absolute;
    right: 0;
    top: -2px;
    left: auto; }
  .main_menu {
    height: 75px; } }

.dropdown-menu {
  border: 0px solid rgba(0, 0, 0, 0.15) !important;
  background-color: #fafafa; }

.dropdown:hover .dropdown-menu {
  display: block; }

.menu_icon:after {
  position: absolute;
  content: "🍔";
  top: 7px;
  left: 0px;
  font-family: 'themify';
  color: #000000;
  font-size: 25px;
  width: 20px;
  height: 20px; }

.single_page_menu .menu_icon:after {
  position: absolute;
  content: "\e68e";
  top: 7px;
  left: 0px;
  font-family: 'themify';
  color: #fff;
  font-size: 25px;
  width: 33px;
  height: 33px; }

.single_page_menu .main-menu-item {
  justify-content: center; }
  .single_page_menu .main-menu-item .navbar-nav {
    margin-left: 0 !important; }

.single_page_menu .btn_1 {
  background-color: #fff;
  border: 1px solid #fff;
  color: #182028;
  font-weight: 500; }
  .single_page_menu .btn_1:hover {
    background-color: #ff4800;
    border: 1px solid #ff4800; }

.menu_fixed {
  position: fixed;
  z-index: 9999 !important;
  width: 100%;
  box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.05);
  top: 0;
  background-color: #562fc7; }
  .menu_fixed .navbar-nav {
    margin-left: 0; }
  .menu_fixed .btn_1 {
    border: 1px solid #fff;
    color: #000; }
    .menu_fixed .btn_1:hover {
      border: 1px solid #ff4800; }
  .menu_fixed .main-menu-item ul li .nav-link {
    color: #fff !important; }
  @media (max-width: 576px) {
    .menu_fixed .main-menu-item ul li .nav-link {
      color: #000 !important; } }
  @media only screen and (min-width: 576px) and (max-width: 767px) {
    .menu_fixed .main-menu-item ul li .nav-link {
      color: #000 !important; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .menu_fixed .main-menu-item ul li .nav-link {
      color: #000 !important; } }
  .menu_fixed .main_logo {
    display: block; }
  .menu_fixed .single_page_logo {
    display: none !important; }
  .menu_fixed .menu_icon:after {
    color: #fff; }
  .menu_fixed .home_page_btn {
    color: #fff; }

/**************** banner part css start ****************/
.banner_part {
  position: relative;
  background-image: url(https://i.ibb.co/5vWtrs7/banner-bg.png);
  background-repeat: no-repeat;
  background-size: 65% 100%;
  background-position: left top; }
  @media (max-width: 991px) {
    .banner_part {
      background-color: #F5F7FA;
      background-image: none; } }
  @media only screen and (min-width: 992px) and (max-width: 1200px) {
    .banner_part {
      background-color: #F5F7FA;
      background-image: none; } }
  .banner_part .banner_text {
    display: table;
    width: 100%;
    height: 1020px; }
    @media only screen and (min-width: 1200px) and (max-width: 1440px) {
      .banner_part .banner_text {
        margin-top: -20px; } }
    @media only screen and (min-width: 1200px) and (max-width: 3640px) {
      .banner_part .banner_text {
        margin-top: -20px; } }
    .banner_part .banner_text .banner_text_iner {
      display: table-cell;
      vertical-align: middle;
      padding-top: 259px; }
      @media (max-width: 991px) {
        .banner_part .banner_text .banner_text_iner {
          padding-top: 100px; } }
      @media only screen and (min-width: 992px) and (max-width: 1200px) {
        .banner_part .banner_text .banner_text_iner {
          height: 650px;
          padding-top: 0; } }
    @media (max-width: 991px) {
      .banner_part .banner_text {
        height: 650px; } }
    @media only screen and (min-width: 992px) and (max-width: 1200px) {
      .banner_part .banner_text {
        height: 650px; } }
    .banner_part .banner_text h1 {
      font-size: 70px;
      text-transform: capitalize;
      font-weight: 700;
      margin-bottom: 40px;
      line-height: 70px; }
      @media (max-width: 576px) {
        .banner_part .banner_text h1 {
          font-size: 25px;
          margin-bottom: 15px;
          line-height: 1.3; } }
      @media only screen and (min-width: 576px) and (max-width: 767px) {
        .banner_part .banner_text h1 {
          font-size: 30px;
          margin-bottom: 15px;
          line-height: 1.3; } }
      @media only screen and (min-width: 768px) and (max-width: 991px) {
        .banner_part .banner_text h1 {
          font-size: 40px;
          margin-bottom: 15px;
          line-height: 1.3; } }
      @media only screen and (min-width: 992px) and (max-width: 1200px) {
        .banner_part .banner_text h1 {
          font-size: 40px;
          margin-bottom: 15px;
          line-height: 1.4; } }
      .banner_part .banner_text h1 span {
        color: #ff4800; }
    .banner_part .banner_text p {
      font-family: "Rajdhani", sans-serif;
      margin-bottom: 42px;
      padding-right: 110px; }
      @media (max-width: 991px) {
        .banner_part .banner_text p {
          padding-right: 0;
          margin-bottom: 10px; } }
      @media only screen and (min-width: 992px) and (max-width: 1200px) {
        .banner_part .banner_text p {
          padding-right: 0; } }
  .banner_part .feature_icon_1 {
    position: absolute;
    right: 10%;
    top: 0%;
    z-index: -1; }
    @media (max-width: 991px) {
      .banner_part .feature_icon_1 {
        display: none; } }
  .banner_part .feature_icon_2 {
    position: absolute;
    right: 30%;
    top: 10%;
    z-index: -1; }
  .banner_part .feature_icon_3 {
    position: absolute;
    right: 10%;
    top: 35%;
    z-index: -1; }
    @media (max-width: 991px) {
      .banner_part .feature_icon_3 {
        display: none; } }
  .banner_part .feature_icon_4 {
    position: absolute;
    right: 30%;
    top: 45%;
    z-index: -1; }
  .banner_part .feature_icon_5 {
    position: absolute;
    right: 15%;
    bottom: 20%;
    z-index: -1; }
  .banner_part .feature_icon_6 {
    position: absolute;
    right: 50%;
    bottom: 5%;
    z-index: -1; }
    @media (max-width: 991px) {
      .banner_part .feature_icon_6 {
        display: none;
        bottom: 0%; } }

/**************** hero part css end ****************/
/**************** service_part css start ****************/
.feature_part {
  position: relative;
  z-index: 1; }
  .feature_part .feature_icon_1 {
    position: absolute;
    left: 19%;
    top: 50%;
    z-index: -1; }
  @media (max-width: 576px) {
    .feature_part .feature_part_text {
      padding-left: 0; } }
  @media only screen and (min-width: 576px) and (max-width: 767px) {
    .feature_part .feature_part_text {
      padding-left: 0; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .feature_part .feature_part_text {
      padding-left: 0; } }
  @media only screen and (min-width: 992px) and (max-width: 1200px) {
    .feature_part .feature_part_text {
      padding-left: 0; } }
  .feature_part .feature_part_text h2 {
    font-size: 45px;
    font-weight: 500;
    line-height: 1.1;
    margin-bottom: 21px;
    position: relative;
    text-transform: capitalize; }
    @media (max-width: 576px) {
      .feature_part .feature_part_text h2 {
        font-size: 25px;
        margin-bottom: 10px;
        line-height: 35px;
        margin-top: 15px; } }
    @media only screen and (min-width: 576px) and (max-width: 767px) {
      .feature_part .feature_part_text h2 {
        font-size: 25px;
        margin-bottom: 10px;
        line-height: 35px;
        margin-top: 20px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .feature_part .feature_part_text h2 {
        font-size: 25px;
        margin-bottom: 10px;
        line-height: 35px;
        margin-top: 20px; } }
    @media only screen and (min-width: 992px) and (max-width: 1200px) {
      .feature_part .feature_part_text h2 {
        font-size: 28px;
        margin-bottom: 20px;
        line-height: 40px; } }
  .feature_part .feature_part_text p {
    margin-bottom: 46px; }
  .feature_part .feature_part_text .feature_part_text_iner {
    border-radius: 50px;
    border-top-right-radius: 0;
    border: 1px solid #eeeeee;
    text-align: center;
    padding: 34px 27px 31px;
    -webkit-transition: 0.5s;
    transition: 0.5s; }
    @media (max-width: 991px) {
      .feature_part .feature_part_text .feature_part_text_iner {
        padding: 20px 15px 15px;
        margin-bottom: 15px; } }
    .feature_part .feature_part_text .feature_part_text_iner:hover {
      border: 1px solid #ff4800; }
    .feature_part .feature_part_text .feature_part_text_iner h4 {
      font-size: 40px;
      font-weight: 700;
      font-family: "Barlow Semi Condensed", sans-serif; }
    .feature_part .feature_part_text .feature_part_text_iner p {
      margin-bottom: 0;
      font-size: 18px;
      font-weight: 300;
      line-height: 1.667; }
  .feature_part .single_feature {
    margin: 0 11px; }
    @media (max-width: 991px) {
      .feature_part .single_feature {
        margin: 0; } }
    @media only screen and (min-width: 992px) and (max-width: 1200px) {
      .feature_part .single_feature {
        margin: 0; } }
    .feature_part .single_feature:nth-child(2) {
      margin-top: 60px; }
      @media (max-width: 991px) {
        .feature_part .single_feature:nth-child(2) {
          margin-top: 30px; } }
      @media only screen and (min-width: 992px) and (max-width: 1200px) {
        .feature_part .single_feature:nth-child(2) {
          margin-top: 30px; } }
  .feature_part .single_feature_part {
    padding: 40px 25px 31px;
    text-align: center;
    border-radius: 5px;
    box-shadow: 0px 3px 81px 0px rgba(216, 216, 216, 0.45);
    background-color: #fff;
    -webkit-transition: 0.6s;
    transition: 0.6s; }
    @media (max-width: 991px) {
      .feature_part .single_feature_part {
        padding: 25px 15px;
        margin-top: 25px; } }
    @media only screen and (min-width: 992px) and (max-width: 1200px) {
      .feature_part .single_feature_part {
        margin-top: 25px; } }
    .feature_part .single_feature_part img {
      margin-bottom: 21px;
      height: 65px; }
      @media (max-width: 991px) {
        .feature_part .single_feature_part img {
          margin-bottom: 21px; } }
      .feature_part .single_feature_part img i {
        color: #0c2e60;
        font-size: 24px;
        -webkit-transition: 0.6s;
        transition: 0.6s; }
    .feature_part .single_feature_part h4 {
      font-weight: 600;
      font-size: 20px;
      margin-bottom: 22px; }
      @media (max-width: 576px) {
        .feature_part .single_feature_part h4 {
          margin-bottom: 15px; } }
      @media only screen and (min-width: 576px) and (max-width: 767px) {
        .feature_part .single_feature_part h4 {
          margin-bottom: 15px; } }
      @media only screen and (min-width: 768px) and (max-width: 991px) {
        .feature_part .single_feature_part h4 {
          margin-bottom: 15px; } }
    .feature_part .single_feature_part p {
      color: #7f7f7f;
      line-height: 1.8;
      font-size: 15px; }

.single_feature_padding {
  padding-top: 140px; }
  @media (max-width: 576px) {
    .single_feature_padding {
      padding-top: 70px; } }
  @media only screen and (min-width: 576px) and (max-width: 767px) {
    .single_feature_padding {
      padding-top: 70px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .single_feature_padding {
      padding-top: 70px; } }
  @media only screen and (min-width: 992px) and (max-width: 1200px) {
    .single_feature_padding {
      padding-top: 70px; } }

/************about_us css start***************/
.about_us {
  position: relative;
  z-index: 99; }
  @media (max-width: 991px) {
    .about_us {
      padding: 0 0 70px; } }
  .about_us .feature_icon_1 {
    position: absolute;
    left: 15%;
    top: 12%;
    z-index: -1; }
  .about_us .feature_icon_2 {
    position: absolute;
    right: 15%;
    top: 12%;
    z-index: -1; }
  .about_us .about_us_text img {
    margin-bottom: 10px; }
  .about_us .about_us_text h2 {
    font-size: 45px;
    font-weight: 500;
    line-height: 55px;
    margin-bottom: 28px;
    position: relative; }
    @media (max-width: 576px) {
      .about_us .about_us_text h2 {
        font-size: 25px;
        margin-bottom: 10px;
        line-height: 35px; } }
    @media only screen and (min-width: 576px) and (max-width: 767px) {
      .about_us .about_us_text h2 {
        font-size: 25px;
        margin-bottom: 10px;
        line-height: 35px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .about_us .about_us_text h2 {
        font-size: 25px;
        margin-bottom: 10px;
        line-height: 35px; } }
    @media only screen and (min-width: 992px) and (max-width: 1200px) {
      .about_us .about_us_text h2 {
        font-size: 28px;
        margin-bottom: 20px;
        line-height: 40px; } }
  .about_us .about_us_text p {
    line-height: 36px;
    margin-bottom: 57px; }
    @media (max-width: 576px) {
      .about_us .about_us_text p {
        margin-bottom: 10px; } }
    @media only screen and (min-width: 576px) and (max-width: 767px) {
      .about_us .about_us_text p {
        margin-bottom: 20px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .about_us .about_us_text p {
        margin-bottom: 20px; } }
  .about_us .about_us_text .btn_1 {
    margin-right: 30px; }
    @media (max-width: 991px) {
      .about_us .about_us_text .btn_1 {
        margin-right: 10px; } }
    @media only screen and (min-width: 992px) and (max-width: 1200px) {
      .about_us .about_us_text .btn_1 {
        margin-right: 10px; } }

@media (max-width: 576px) {
  .learning_img {
    margin-top: 30px; }
  .right_time .learning_img {
    margin-top: 0; } }

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .learning_img {
    margin-top: 40px; }
  .right_time .learning_img {
    margin-top: 0; } }

@media (max-width: 991px) {
  .right_time {
    padding: 70px 0 0; }
    .right_time .about_us_text h2 {
      margin-top: 30px; } }

/************use_sasu css start***************/
.use_sasu {
  position: relative;
  z-index: 1;
  position: relative;
  z-index: 99; }
  @media (max-width: 991px) {
    .use_sasu {
      padding: 70px 0 30px; } }
  .use_sasu .feature_icon_1 {
    position: absolute;
    left: 22%;
    top: 15%;
    z-index: -1; }
  .use_sasu .single_feature_part {
    text-align: center;
    padding: 0 15px; }
    @media (max-width: 991px) {
      .use_sasu .single_feature_part {
        margin-bottom: 40px; } }
    .use_sasu .single_feature_part img {
      margin-bottom: 33px;
      height: 65px; }
      @media (max-width: 991px) {
        .use_sasu .single_feature_part img {
          margin-bottom: 21px; } }
      .use_sasu .single_feature_part img i {
        color: #0c2e60;
        font-size: 24px;
        -webkit-transition: 0.6s;
        transition: 0.6s; }
    .use_sasu .single_feature_part h4 {
      font-weight: 600;
      font-size: 20px;
      margin-bottom: 22px; }
      @media (max-width: 576px) {
        .use_sasu .single_feature_part h4 {
          margin-bottom: 15px; } }
      @media only screen and (min-width: 576px) and (max-width: 767px) {
        .use_sasu .single_feature_part h4 {
          margin-bottom: 15px; } }
      @media only screen and (min-width: 768px) and (max-width: 991px) {
        .use_sasu .single_feature_part h4 {
          margin-bottom: 15px; } }
    .use_sasu .single_feature_part p {
      color: #7f7f7f;
      line-height: 1.8;
      font-size: 15px; }

/************use_sasu css start***************/
.pricing_part {
  position: relative;
  z-index: 1;
  position: relative;
  z-index: 99; }
  @media (max-width: 991px) {
    .pricing_part {
      padding: 70px 0 40px; } }
  .pricing_part .feature_icon_2 {
    position: absolute;
    left: 15%;
    top: 8%;
    z-index: -1; }
  .pricing_part .single_pricing_part {
    text-align: center;
    padding: 40px 40px 32px;
    border: 1px solid #eaecee;
    -webkit-transition: all 0.6s ease 0s;
    -moz-transition: all 0.6s ease 0s;
    -o-transition: all 0.6s ease 0s;
    transition: all 0.6s ease 0s; }
    @media (max-width: 991px) {
      .pricing_part .single_pricing_part {
        padding: 40px 30px;
        margin-bottom: 30px; } }
    @media only screen and (min-width: 992px) and (max-width: 1200px) {
      .pricing_part .single_pricing_part {
        padding: 40px; } }
    .pricing_part .single_pricing_part img {
      margin-bottom: 18px;
      height: 65px; }
    .pricing_part .single_pricing_part p {
      color: #182028;
      font-size: 30px;
      font-weight: 600;
      text-transform: capitalize;
      margin-bottom: 27px; }
    .pricing_part .single_pricing_part h3 {
      font-size: 30px;
      font-weight: 600;
      border-top: 1px solid #eee;
      margin-bottom: 5px;
      padding-top: 24px; }
      .pricing_part .single_pricing_part h3 span {
        font-size: 16px;
        color: #919191;
        font-weight: 400; }
    .pricing_part .single_pricing_part .pricing_btn {
      font-size: 15px;
      font-weight: 600;
      text-transform: uppercase;
      letter-spacing: 1.8px;
      display: inline-block;
      color: #182028;
      -webkit-transition: 0.5s;
      -moz-transition: 0.5s;
      -o-transition: 0.5s;
      transition: 0.5s; }
      .pricing_part .single_pricing_part .pricing_btn:hover {
        color: #ff4800; }
    .pricing_part .single_pricing_part ul {
      padding: 12px 0 20px;
      border-bottom: 1px solid #eaecee; }
      @media (max-width: 991px) {
        .pricing_part .single_pricing_part ul {
          margin: 16px 0; } }
      @media only screen and (min-width: 992px) and (max-width: 1200px) {
        .pricing_part .single_pricing_part ul {
          margin: 20px 0; } }
      .pricing_part .single_pricing_part ul li {
        font-size: 14px;
        color: #7f7f7f;
        text-transform: capitalize;
        margin: 10px 0; }
    .pricing_part .single_pricing_part .pricing_btn {
      padding-top: 33px; }
      @media (max-width: 991px) {
        .pricing_part .single_pricing_part .pricing_btn {
          padding-top: 10px; } }
    .pricing_part .single_pricing_part:hover {
      border: 1px solid transparent;
      box-shadow: 0px 20px 30px 0px rgba(24, 32, 40, 0.05); }
      .pricing_part .single_pricing_part:hover .pricing_btn {
        color: #ff4800; }

.single_page_pricing .left_shape_1 {
  display: none; }

/************review_part css start***************/
.review_part {
  position: relative;
  z-index: 99; }
  .review_part .feature_icon_2 {
    position: absolute;
    right: 15%;
    top: 5%;
    z-index: -1; }
  .review_part .review_img {
    margin-bottom: 25px; }
  .review_part .review_part_text h2 {
    font-size: 45px;
    font-weight: 500;
    line-height: 55px;
    margin-bottom: 28px;
    position: relative; }
    @media (max-width: 576px) {
      .review_part .review_part_text h2 {
        font-size: 25px;
        margin-bottom: 10px;
        line-height: 35px; } }
    @media only screen and (min-width: 576px) and (max-width: 767px) {
      .review_part .review_part_text h2 {
        font-size: 25px;
        margin-bottom: 10px;
        line-height: 35px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .review_part .review_part_text h2 {
        font-size: 25px;
        margin-bottom: 10px;
        line-height: 35px; } }
    @media only screen and (min-width: 992px) and (max-width: 1200px) {
      .review_part .review_part_text h2 {
        font-size: 28px;
        margin-bottom: 20px;
        line-height: 40px; } }
  .review_part .review_part_text p {
    line-height: 36px;
    margin-bottom: 34px; }
    @media (max-width: 576px) {
      .review_part .review_part_text p {
        margin-bottom: 10px; } }
    @media only screen and (min-width: 576px) and (max-width: 767px) {
      .review_part .review_part_text p {
        margin-bottom: 20px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .review_part .review_part_text p {
        margin-bottom: 20px; } }
  .review_part .review_part_text h3 {
    font-size: 24px;
    font-weight: 600; }
    .review_part .review_part_text h3 span {
      font-size: 70%;
      color: #888888;
      font-weight: 400; }
  .review_part .owl-dots {
    padding-top: 33px; }
    .review_part .owl-dots button.owl-dot {
      border-radius: 50px;
      display: inline-block;
      background: #ccc;
      margin: 0 15px 0 0;
      width: 20px;
      height: 4px;
      background-color: #d3d8dd; }
      .review_part .owl-dots button.owl-dot.active {
        background-color: #ff4800; }
      .review_part .owl-dots button.owl-dot:focus {
        outline: none; }

/************subscribe us css start***************/
.subscribe_part {
  background: #F5F7FA;
  position: relative;
  z-index: 1; }
  .subscribe_part .feature_icon_2 {
    position: absolute;
    right: 50%;
    bottom: 5%;
    z-index: -1; }
  .subscribe_part h2 {
    font-size: 45px;
    font-weight: 600;
    line-height: 55px;
    margin-bottom: 77px; }
    @media (max-width: 991px) {
      .subscribe_part h2 {
        font-size: 25px;
        margin-bottom: 25px;
        line-height: 30px; } }
    @media only screen and (min-width: 992px) and (max-width: 1200px) {
      .subscribe_part h2 {
        font-size: 30px;
        margin-bottom: 25px;
        line-height: 35px; } }
  .subscribe_part .btn_2 {
    border-radius: 0;
    padding: 27px 44px; }
    @media (max-width: 991px) {
      .subscribe_part .btn_2 {
        padding: 17px 15px; } }
    @media only screen and (min-width: 576px) and (max-width: 767px) {
      .subscribe_part .btn_2 {
        margin-top: 0;
        padding: 13px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .subscribe_part .btn_2 {
        margin-top: 0;
        padding: 13px; } }
    @media only screen and (min-width: 992px) and (max-width: 1200px) {
      .subscribe_part .btn_2 {
        padding: 13px; } }
  .subscribe_part .form-control {
    padding: 39px 20px;
    border-radius: 0;
    text-transform: uppercase;
    font-size: 14px;
    letter-spacing: 1.2px; }
    @media (max-width: 991px) {
      .subscribe_part .form-control {
        padding: 25px 15px; } }
    @media only screen and (min-width: 992px) and (max-width: 1200px) {
      .subscribe_part .form-control {
        padding: 25px 15px; } }

/************client css start***************/
.client_logo {
  padding: 0 0 140px; }
  @media (max-width: 991px) {
    .client_logo {
      padding: 0 0 70px; } }
  @media only screen and (min-width: 992px) and (max-width: 1200px) {
    .client_logo {
      padding: 0 0 100px; } }
  .client_logo .single_client_logo {
    display: flex;
    align-items: center; }
  .client_logo .owl-carousel .owl-item img {
    width: auto; }
  .client_logo .single_client_logo {
    height: 100px; }
    .client_logo .single_client_logo img {
      margin: 0 auto; }

/**********************footer part css*******************/
.footer_part {
  background-image: url("https://i.ibb.co/THXWPMd/footer-bg.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 130px 0px 25px; }
  @media (max-width: 991px) {
    .footer_part {
      padding: 70px 0px 25px;
      background-image: url("none");
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      background-color: #f7f7f7; } }
  .footer_part img {
    margin-bottom: 35px; }
  @media (max-width: 576px) {
    .footer_part .single_footer_part {
      margin-bottom: 20px; } }
  @media only screen and (min-width: 576px) and (max-width: 767px) {
    .footer_part .single_footer_part {
      margin-bottom: 20px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .footer_part .single_footer_part {
      margin-bottom: 20px; } }
  .footer_part .single_footer_part h4 {
    font-size: 20px;
    margin-bottom: 30px;
    font-weight: 500; }
    @media (max-width: 576px) {
      .footer_part .single_footer_part h4 {
        margin-bottom: 15px; } }
    @media only screen and (min-width: 576px) and (max-width: 767px) {
      .footer_part .single_footer_part h4 {
        margin-bottom: 15px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .footer_part .single_footer_part h4 {
        margin-bottom: 15px; } }
  .footer_part .single_footer_part p {
    margin-bottom: 10px; }
  .footer_part .single_footer_part .list-unstyled li a {
    color: #888888;
    margin-bottom: 10px;
    display: inline-block; }
    .footer_part .single_footer_part .list-unstyled li a:hover {
      color: #ff4800 !important; }
  .footer_part .single_footer_part .social_icon {
    float: left;
    margin-top: 38px; }
    @media (max-width: 991px) {
      .footer_part .single_footer_part .social_icon {
        margin-top: 15px; } }
    .footer_part .single_footer_part .social_icon li a {
      margin-left: 0;
      margin-right: 25px; }
  .footer_part .single_footer_part .mail_part {
    position: relative; }
    .footer_part .single_footer_part .mail_part input {
      padding: 9px 22px;
      font-size: 13px;
      border-radius: 50px;
      border: 2px solid #fff;
      margin-top: 20px;
      width: 100%;
      background-color: #f9f9fe;
      border: 1px solid #ebebeb; }
    .footer_part .single_footer_part .mail_part .email_icon {
      position: absolute;
      right: 0px;
      top: 20px;
      width: 60px;
      height: 39px;
      background-color: #ff4800;
      text-align: center;
      color: #fff;
      border-radius: 50px;
      line-height: 10px;
      border: 2px solid #ff4800; }
  .footer_part hr {
    background-color: #cad7dc;
    margin-top: 50px;
    margin-bottom: 25px; }
  .footer_part .footer_icon {
    text-align: right; }
    .footer_part .footer_icon li {
      display: inline-block; }
      .footer_part .footer_icon li a {
        font-size: 14px;
        color: #888888;
        margin-left: 30px;
        -webkit-transition: 0.5s;
        transition: 0.5s; }
        @media (max-width: 576px) {
          .footer_part .footer_icon li a {
            margin-left: 0px;
            margin-right: 25px; } }
        @media only screen and (min-width: 576px) and (max-width: 767px) {
          .footer_part .footer_icon li a {
            margin-left: 0px;
            margin-right: 25px; } }
        .footer_part .footer_icon li a :hover {
          color: #ff4800; }
    @media (max-width: 576px) {
      .footer_part .footer_icon {
        float: left;
        margin-top: 15px; } }
  .footer_part .copyright_text p {
    color: #888; }
  .footer_part .copyright_text span {
    color: #ff4800;
    font-size: 12px; }
  @media (max-width: 991px) {
    .footer_part .copyright_text {
      text-align: center !important; } }
  .footer_part .copyright_text p a {
    color: #ff4800;
    -webkit-transition: 0.5s;
    transition: 0.5s; }
    .footer_part .copyright_text p a:hover {
      color: #ff4800; }
  .footer_part .info {
    color: red !important; }
  @media (max-width: 991px) {
    .footer_part .footer_icon {
      text-align: center;
      float: none; } }

.sample-text-area {
  background: #fff;
  padding: 100px 0 70px 0; }

.text-heading {
  margin-bottom: 30px;
  font-size: 24px; }

b,
sup,
sub,
u,
del {
  color: #ff4800; }

h1 {
  font-size: 36px; }

h2 {
  font-size: 30px; }

h3 {
  font-size: 24px; }

h4 {
  font-size: 18px; }

h5 {
  font-size: 16px; }

h6 {
  font-size: 14px; }

h1, h2, h3, h4, h5, h6 {
  line-height: 1.2em; }

.typography h1,
.typography h2,
.typography h3,
.typography h4,
.typography h5,
.typography h6 {
  color: #828bb2; }

.button-area {
  background: #fff; }
  .button-area .border-top-generic {
    padding: 70px 15px;
    border-top: 1px dotted #eee; }

.button-group-area .genric-btn {
  margin-right: 10px;
  margin-top: 10px; }
  .button-group-area .genric-btn:last-child {
    margin-right: 0; }

.genric-btn {
  display: inline-block;
  outline: none;
  line-height: 40px;
  padding: 0 30px;
  font-size: .8em;
  text-align: center;
  text-decoration: none;
  font-weight: 500;
  cursor: pointer;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s; }
  .genric-btn:focus {
    outline: none; }
  .genric-btn.e-large {
    padding: 0 40px;
    line-height: 50px; }
  .genric-btn.large {
    line-height: 45px; }
  .genric-btn.medium {
    line-height: 30px; }
  .genric-btn.small {
    line-height: 25px; }
  .genric-btn.radius {
    border-radius: 3px; }
  .genric-btn.circle {
    border-radius: 20px; }
  .genric-btn.arrow {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center; }
    .genric-btn.arrow span {
      margin-left: 10px; }
  .genric-btn.default {
    color: #415094;
    background: #f9f9ff;
    border: 1px solid transparent; }
    .genric-btn.default:hover {
      border: 1px solid #f9f9ff;
      background: #fff; }
  .genric-btn.default-border {
    border: 1px solid #f9f9ff;
    background: #fff; }
    .genric-btn.default-border:hover {
      color: #415094;
      background: #f9f9ff;
      border: 1px solid transparent; }
  .genric-btn.primary {
    color: #fff;
    background: #ff4800;
    border: 1px solid transparent; }
    .genric-btn.primary:hover {
      color: #ff4800;
      border: 1px solid #ff4800;
      background: #fff; }
  .genric-btn.primary-border {
    color: #ff4800;
    border: 1px solid #ff4800;
    background: #fff; }
    .genric-btn.primary-border:hover {
      color: #fff;
      background: #ff4800;
      border: 1px solid transparent; }
  .genric-btn.success {
    color: #fff;
    background: #4cd3e3;
    border: 1px solid transparent; }
    .genric-btn.success:hover {
      color: #4cd3e3;
      border: 1px solid #4cd3e3;
      background: #fff; }
  .genric-btn.success-border {
    color: #4cd3e3;
    border: 1px solid #4cd3e3;
    background: #fff; }
    .genric-btn.success-border:hover {
      color: #fff;
      background: #4cd3e3;
      border: 1px solid transparent; }
  .genric-btn.info {
    color: #fff;
    background: #38a4ff;
    border: 1px solid transparent; }
    .genric-btn.info:hover {
      color: #38a4ff;
      border: 1px solid #38a4ff;
      background: #fff; }
  .genric-btn.info-border {
    color: #38a4ff;
    border: 1px solid #38a4ff;
    background: #fff; }
    .genric-btn.info-border:hover {
      color: #fff;
      background: #38a4ff;
      border: 1px solid transparent; }
  .genric-btn.warning {
    color: #fff;
    background: #f4e700;
    border: 1px solid transparent; }
    .genric-btn.warning:hover {
      color: #f4e700;
      border: 1px solid #f4e700;
      background: #fff; }
  .genric-btn.warning-border {
    color: #f4e700;
    border: 1px solid #f4e700;
    background: #fff; }
    .genric-btn.warning-border:hover {
      color: #fff;
      background: #f4e700;
      border: 1px solid transparent; }
  .genric-btn.danger {
    color: #fff;
    background: #f44a40;
    border: 1px solid transparent; }
    .genric-btn.danger:hover {
      color: #f44a40;
      border: 1px solid #f44a40;
      background: #fff; }
  .genric-btn.danger-border {
    color: #f44a40;
    border: 1px solid #f44a40;
    background: #fff; }
    .genric-btn.danger-border:hover {
      color: #fff;
      background: #f44a40;
      border: 1px solid transparent; }
  .genric-btn.link {
    color: #415094;
    background: #f9f9ff;
    text-decoration: underline;
    border: 1px solid transparent; }
    .genric-btn.link:hover {
      color: #415094;
      border: 1px solid #f9f9ff;
      background: #fff; }
  .genric-btn.link-border {
    color: #415094;
    border: 1px solid #f9f9ff;
    background: #fff;
    text-decoration: underline; }
    .genric-btn.link-border:hover {
      color: #415094;
      background: #f9f9ff;
      border: 1px solid transparent; }
  .genric-btn.disable {
    color: #222222, 0.3;
    background: #f9f9ff;
    border: 1px solid transparent;
    cursor: not-allowed; }

.generic-blockquote {
  padding: 30px 50px 30px 30px;
  background: #f9f9ff;
  border-left: 2px solid #ff4800; }

.progress-table-wrap {
  overflow-x: scroll; }

.progress-table {
  background: #f9f9ff;
  padding: 15px 0px 30px 0px;
  min-width: 800px; }
  .progress-table .serial {
    width: 11.83%;
    padding-left: 30px; }
  .progress-table .country {
    width: 28.07%; }
  .progress-table .visit {
    width: 19.74%; }
  .progress-table .percentage {
    width: 40.36%;
    padding-right: 50px; }
  .progress-table .table-head {
    display: flex; }
    .progress-table .table-head .serial,
    .progress-table .table-head .country,
    .progress-table .table-head .visit,
    .progress-table .table-head .percentage {
      color: #415094;
      line-height: 40px;
      text-transform: uppercase;
      font-weight: 500; }
  .progress-table .table-row {
    padding: 15px 0;
    border-top: 1px solid #edf3fd;
    display: flex; }
    .progress-table .table-row .serial,
    .progress-table .table-row .country,
    .progress-table .table-row .visit,
    .progress-table .table-row .percentage {
      display: flex;
      align-items: center; }
    .progress-table .table-row .country img {
      margin-right: 15px; }
    .progress-table .table-row .percentage .progress {
      width: 80%;
      border-radius: 0px;
      background: transparent; }
      .progress-table .table-row .percentage .progress .progress-bar {
        height: 5px;
        line-height: 5px; }
        .progress-table .table-row .percentage .progress .progress-bar.color-1 {
          background-color: #6382e6; }
        .progress-table .table-row .percentage .progress .progress-bar.color-2 {
          background-color: #e66686; }
        .progress-table .table-row .percentage .progress .progress-bar.color-3 {
          background-color: #f09359; }
        .progress-table .table-row .percentage .progress .progress-bar.color-4 {
          background-color: #73fbaf; }
        .progress-table .table-row .percentage .progress .progress-bar.color-5 {
          background-color: #73fbaf; }
        .progress-table .table-row .percentage .progress .progress-bar.color-6 {
          background-color: #6382e6; }
        .progress-table .table-row .percentage .progress .progress-bar.color-7 {
          background-color: #a367e7; }
        .progress-table .table-row .percentage .progress .progress-bar.color-8 {
          background-color: #e66686; }

.single-gallery-image {
  margin-top: 30px;
  background-repeat: no-repeat !important;
  background-position: center center !important;
  background-size: cover !important;
  height: 200px; }

.list-style {
  width: 14px;
  height: 14px; }

.unordered-list li {
  position: relative;
  padding-left: 30px;
  line-height: 1.82em !important; }
  .unordered-list li:before {
    content: "";
    position: absolute;
    width: 14px;
    height: 14px;
    border: 3px solid #ff4800;
    background: #fff;
    top: 4px;
    left: 0;
    border-radius: 50%; }

.ordered-list {
  margin-left: 30px; }
  .ordered-list li {
    list-style-type: decimal-leading-zero;
    color: #ff4800;
    font-weight: 500;
    line-height: 1.82em !important; }
    .ordered-list li span {
      font-weight: 300;
      color: #828bb2; }

.ordered-list-alpha li {
  margin-left: 30px;
  list-style-type: lower-alpha;
  color: #ff4800;
  font-weight: 500;
  line-height: 1.82em !important; }
  .ordered-list-alpha li span {
    font-weight: 300;
    color: #828bb2; }

.ordered-list-roman li {
  margin-left: 30px;
  list-style-type: lower-roman;
  color: #ff4800;
  font-weight: 500;
  line-height: 1.82em !important; }
  .ordered-list-roman li span {
    font-weight: 300;
    color: #828bb2; }

.single-input {
  display: block;
  width: 100%;
  line-height: 40px;
  border: none;
  outline: none;
  background: #f9f9ff;
  padding: 0 20px; }
  .single-input:focus {
    outline: none; }

.input-group-icon {
  position: relative; }
  .input-group-icon .icon {
    position: absolute;
    left: 20px;
    top: 0;
    line-height: 40px;
    z-index: 3; }
    .input-group-icon .icon i {
      color: #797979; }
  .input-group-icon .single-input {
    padding-left: 45px; }

.single-textarea {
  display: block;
  width: 100%;
  line-height: 40px;
  border: none;
  outline: none;
  background: #f9f9ff;
  padding: 0 20px;
  height: 100px;
  resize: none; }
  .single-textarea:focus {
    outline: none; }

.single-input-primary {
  display: block;
  width: 100%;
  line-height: 40px;
  border: 1px solid transparent;
  outline: none;
  background: #f9f9ff;
  padding: 0 20px; }
  .single-input-primary:focus {
    outline: none;
    border: 1px solid #ff4800; }

.single-input-accent {
  display: block;
  width: 100%;
  line-height: 40px;
  border: 1px solid transparent;
  outline: none;
  background: #f9f9ff;
  padding: 0 20px; }
  .single-input-accent:focus {
    outline: none;
    border: 1px solid #eb6b55; }

.single-input-secondary {
  display: block;
  width: 100%;
  line-height: 40px;
  border: 1px solid transparent;
  outline: none;
  background: #f9f9ff;
  padding: 0 20px; }
  .single-input-secondary:focus {
    outline: none;
    border: 1px solid #f09359; }

.default-switch {
  width: 35px;
  height: 17px;
  border-radius: 8.5px;
  background: #f9f9ff;
  position: relative;
  cursor: pointer; }
  .default-switch input {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer; }
    .default-switch input + label {
      position: absolute;
      top: 1px;
      left: 1px;
      width: 15px;
      height: 15px;
      border-radius: 50%;
      background: #ff4800;
      -webkit-transition: all 0.2s;
      -moz-transition: all 0.2s;
      -o-transition: all 0.2s;
      transition: all 0.2s;
      box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.2);
      cursor: pointer; }
    .default-switch input:checked + label {
      left: 19px; }

.primary-switch {
  width: 35px;
  height: 17px;
  border-radius: 8.5px;
  background: #f9f9ff;
  position: relative;
  cursor: pointer; }
  .primary-switch input {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    opacity: 0; }
    .primary-switch input + label {
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%; }
      .primary-switch input + label:before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        background: transparent;
        border-radius: 8.5px;
        cursor: pointer;
        -webkit-transition: all 0.2s;
        -moz-transition: all 0.2s;
        -o-transition: all 0.2s;
        transition: all 0.2s; }
      .primary-switch input + label:after {
        content: "";
        position: absolute;
        top: 1px;
        left: 1px;
        width: 15px;
        height: 15px;
        border-radius: 50%;
        background: #fff;
        -webkit-transition: all 0.2s;
        -moz-transition: all 0.2s;
        -o-transition: all 0.2s;
        transition: all 0.2s;
        box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.2);
        cursor: pointer; }
    .primary-switch input:checked + label:after {
      left: 19px; }
    .primary-switch input:checked + label:before {
      background: #ff4800; }

.confirm-switch {
  width: 35px;
  height: 17px;
  border-radius: 8.5px;
  background: #f9f9ff;
  position: relative;
  cursor: pointer; }
  .confirm-switch input {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    opacity: 0; }
    .confirm-switch input + label {
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%; }
      .confirm-switch input + label:before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        background: transparent;
        border-radius: 8.5px;
        -webkit-transition: all 0.2s;
        -moz-transition: all 0.2s;
        -o-transition: all 0.2s;
        transition: all 0.2s;
        cursor: pointer; }
      .confirm-switch input + label:after {
        content: "";
        position: absolute;
        top: 1px;
        left: 1px;
        width: 15px;
        height: 15px;
        border-radius: 50%;
        background: #fff;
        -webkit-transition: all 0.2s;
        -moz-transition: all 0.2s;
        -o-transition: all 0.2s;
        transition: all 0.2s;
        box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.2);
        cursor: pointer; }
    .confirm-switch input:checked + label:after {
      left: 19px; }
    .confirm-switch input:checked + label:before {
      background: #4cd3e3; }

.primary-checkbox {
  width: 16px;
  height: 16px;
  border-radius: 3px;
  background: #f9f9ff;
  position: relative;
  cursor: pointer; }
  .primary-checkbox input {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    opacity: 0; }
    .primary-checkbox input + label {
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      border-radius: 3px;
      cursor: pointer;
      border: 1px solid #f1f1f1; }
    .primary-checkbox input:checked + label {
      border: none; }

.confirm-checkbox {
  width: 16px;
  height: 16px;
  border-radius: 3px;
  background: #f9f9ff;
  position: relative;
  cursor: pointer; }
  .confirm-checkbox input {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    opacity: 0; }
    .confirm-checkbox input + label {
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      border-radius: 3px;
      cursor: pointer;
      border: 1px solid #f1f1f1; }
    .confirm-checkbox input:checked + label {
      border: none; }

.disabled-checkbox {
  width: 16px;
  height: 16px;
  border-radius: 3px;
  background: #f9f9ff;
  position: relative;
  cursor: pointer; }
  .disabled-checkbox input {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    opacity: 0; }
    .disabled-checkbox input + label {
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      border-radius: 3px;
      cursor: pointer;
      border: 1px solid #f1f1f1; }
    .disabled-checkbox input:disabled {
      cursor: not-allowed;
      z-index: 3; }
    .disabled-checkbox input:checked + label {
      border: none; }

.primary-radio {
  width: 16px;
  height: 16px;
  border-radius: 8px;
  background: #f9f9ff;
  position: relative;
  cursor: pointer; }
  .primary-radio input {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    opacity: 0; }
    .primary-radio input + label {
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      border-radius: 8px;
      cursor: pointer;
      border: 1px solid #f1f1f1; }
    .primary-radio input:checked + label {
      border: none; }

.confirm-radio {
  width: 16px;
  height: 16px;
  border-radius: 8px;
  background: #f9f9ff;
  position: relative;
  cursor: pointer; }
  .confirm-radio input {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    opacity: 0; }
    .confirm-radio input + label {
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      border-radius: 8px;
      cursor: pointer;
      border: 1px solid #f1f1f1; }
    .confirm-radio input:checked + label {
      border: none; }

.disabled-radio {
  width: 16px;
  height: 16px;
  border-radius: 8px;
  background: #f9f9ff;
  position: relative;
  cursor: pointer; }
  .disabled-radio input {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    opacity: 0; }
    .disabled-radio input + label {
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      border-radius: 8px;
      cursor: pointer;
      border: 1px solid #f1f1f1; }
    .disabled-radio input:disabled {
      cursor: not-allowed;
      z-index: 3; }
    .disabled-radio input:checked + label {
      border: none; }

.default-select {
  height: 40px; }
  .default-select .nice-select {
    border: none;
    border-radius: 0px;
    height: 40px;
    background: #f9f9ff;
    padding-left: 20px;
    padding-right: 40px; }
    .default-select .nice-select .list {
      margin-top: 0;
      border: none;
      border-radius: 0px;
      box-shadow: none;
      width: 100%;
      padding: 10px 0 10px 0px; }
      .default-select .nice-select .list .option {
        font-weight: 300;
        -webkit-transition: all 0.3s ease 0s;
        -moz-transition: all 0.3s ease 0s;
        -o-transition: all 0.3s ease 0s;
        transition: all 0.3s ease 0s;
        line-height: 28px;
        min-height: 28px;
        font-size: 12px;
        padding-left: 20px; }
        .default-select .nice-select .list .option.selected {
          color: #ff4800;
          background: transparent; }
        .default-select .nice-select .list .option:hover {
          color: #ff4800;
          background: transparent; }
  .default-select .current {
    margin-right: 50px;
    font-weight: 300; }
  .default-select .nice-select::after {
    right: 20px; }

.form-select {
  height: 40px;
  width: 100%; }
  .form-select .nice-select {
    border: none;
    border-radius: 0px;
    height: 40px;
    background: #f9f9ff;
    padding-left: 45px;
    padding-right: 40px;
    width: 100%; }
    .form-select .nice-select .list {
      margin-top: 0;
      border: none;
      border-radius: 0px;
      box-shadow: none;
      width: 100%;
      padding: 10px 0 10px 0px; }
      .form-select .nice-select .list .option {
        font-weight: 300;
        -webkit-transition: all 0.3s ease 0s;
        -moz-transition: all 0.3s ease 0s;
        -o-transition: all 0.3s ease 0s;
        transition: all 0.3s ease 0s;
        line-height: 28px;
        min-height: 28px;
        font-size: 12px;
        padding-left: 45px; }
        .form-select .nice-select .list .option.selected {
          color: #ff4800;
          background: transparent; }
        .form-select .nice-select .list .option:hover {
          color: #ff4800;
          background: transparent; }
  .form-select .current {
    margin-right: 50px;
    font-weight: 300; }
  .form-select .nice-select::after {
    right: 20px; }

.mt-10 {
  margin-top: 10px; }

.section-top-border {
  padding: 50px 0;
  border-top: 1px dotted #eee; }

.mb-30 {
  margin-bottom: 30px; }

.mt-30 {
  margin-top: 30px; }

.switch-wrap {
  margin-bottom: 10px; }

/**************** blog part css start ****************/
@media (max-width: 576px) {
  .blog_part {
    padding-bottom: 50px; } }

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .blog_part {
    padding-bottom: 50px; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog_part {
    padding-bottom: 50px; } }

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .blog_part {
    padding-bottom: 50px; } }

.blog_part .card {
  border: 0px solid transparent; }

.blog_part .blog_right_sidebar .widget_title {
  font-size: 20px;
  margin-bottom: 40px;
  font-style: inherit !important; }

@media (max-width: 576px) {
  .blog_part .single-home-blog {
    margin-bottom: 20px; } }

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .blog_part .single-home-blog {
    margin-bottom: 20px; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog_part .single-home-blog {
    margin-bottom: 20px; } }

.blog_part .single-home-blog .card-img-top {
  border-radius: 0px; }

.blog_part .single-home-blog .card {
  border-radius: 0px;
  background-color: transparent;
  position: relative; }
  .blog_part .single-home-blog .card .card-body {
    padding: 35px 30px 23px;
    background-color: #fff;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    border: 1px solid #edeff2; }
    .blog_part .single-home-blog .card .card-body:hover {
      box-shadow: 0px 10px 30px 0px rgba(12, 46, 96, 0.1);
      border: 1px solid transparent; }
    @media (max-width: 576px) {
      .blog_part .single-home-blog .card .card-body {
        padding: 15px 10px; } }
    @media only screen and (min-width: 576px) and (max-width: 767px) {
      .blog_part .single-home-blog .card .card-body {
        padding: 15px; } }
    @media only screen and (min-width: 992px) and (max-width: 1200px) {
      .blog_part .single-home-blog .card .card-body {
        padding: 20px; } }
    .blog_part .single-home-blog .card .card-body .btn_4 {
      margin-bottom: 20px; }
    .blog_part .single-home-blog .card .card-body a {
      color: #fff;
      text-transform: capitalize;
      -webkit-transition: 0.8s;
      transition: 0.8s; }
  .blog_part .single-home-blog .card .dot {
    position: relative;
    padding-left: 20px; }
    .blog_part .single-home-blog .card .dot:after {
      position: absolute;
      content: "";
      width: 10px;
      height: 10px;
      top: 5px;
      left: 0;
      background-color: #ff4800;
      border-radius: 50%; }
  .blog_part .single-home-blog .card span {
    color: #8a8a8a;
    margin-bottom: 10px;
    display: inline-block;
    margin-top: 10px; }
    @media (max-width: 576px) {
      .blog_part .single-home-blog .card span {
        margin-bottom: 5px;
        margin-top: 5px; } }
    @media only screen and (min-width: 576px) and (max-width: 767px) {
      .blog_part .single-home-blog .card span {
        margin-bottom: 5px;
        margin-top: 5px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .blog_part .single-home-blog .card span {
        margin-bottom: 5px;
        margin-top: 5px; } }
    @media only screen and (min-width: 992px) and (max-width: 1200px) {
      .blog_part .single-home-blog .card span {
        margin-bottom: 5px;
        margin-top: 5px; } }
  .blog_part .single-home-blog .card h5 {
    font-weight: 600;
    line-height: 1.5;
    font-size: 19px;
    -webkit-transition: 0.8s;
    transition: 0.8s;
    text-transform: capitalize; }
    @media (max-width: 576px) {
      .blog_part .single-home-blog .card h5 {
        margin-bottom: 5px;
        font-size: 17px; } }
    @media only screen and (min-width: 576px) and (max-width: 767px) {
      .blog_part .single-home-blog .card h5 {
        margin-bottom: 10px;
        font-size: 16px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .blog_part .single-home-blog .card h5 {
        margin-bottom: 10px; } }
    @media only screen and (min-width: 992px) and (max-width: 1200px) {
      .blog_part .single-home-blog .card h5 {
        margin-bottom: 10px;
        font-size: 18px; } }
    .blog_part .single-home-blog .card h5:hover {
      -webkit-transition: 0.8s;
      transition: 0.8s;
      color: #ff4800; }
  .blog_part .single-home-blog .card ul {
    border-top: 1px solid #edeff2;
    padding-top: 20px;
    margin-top: 24px; }
    .blog_part .single-home-blog .card ul li {
      display: inline-block;
      color: #8a8a8a;
      margin-right: 39px; }
      @media (max-width: 576px) {
        .blog_part .single-home-blog .card ul li {
          margin-right: 10px; } }
      @media only screen and (min-width: 576px) and (max-width: 767px) {
        .blog_part .single-home-blog .card ul li {
          margin-right: 10px; } }
      @media only screen and (min-width: 768px) and (max-width: 991px) {
        .blog_part .single-home-blog .card ul li {
          margin-right: 10px; } }
      @media only screen and (min-width: 992px) and (max-width: 1200px) {
        .blog_part .single-home-blog .card ul li {
          margin-right: 10px; } }
      .blog_part .single-home-blog .card ul li span {
        margin-right: 10px; }

/************** accordion part start***************/
.accordion_part button {
  font-size: 20px;
  color: #182028;
  font-weight: 500; }

.accordion_part .card {
  margin-bottom: 30px;
  border-radius: 0; }
  .accordion_part .card .card-header {
    background-color: transparent;
    border-bottom: 1px solid transparent;
    padding: 27px 16px; }
  .accordion_part .card .card-body {
    font-size: 16px;
    line-height: 28px;
    color: #888888;
    padding: 0px 30px 32px; }

.accordion_part h5 {
  position: relative;
  z-index: 1; }
  .accordion_part h5 button.btn.btn-link:after {
    position: absolute;
    content: "👇";
    top: 9px;
    right: 12px;
    height: auto;
    font-family: 'themify';
    color: #ff4800; }
  .accordion_part h5 button.btn.btn-link.collapsed:after {
    content: "👇";
    color: #888888; }

.accordion_part .btn-link:hover {
  color: #182028;
  text-decoration: none; }

.accordion_part .btn {
  text-align: left !important; }

/**************** copyright part css start ****************/
.copyright_part {
  background-color: #303030;
  padding: 26px 0px; }
  .copyright_part p {
    color: #8a8a8a;
    font-family: 300; }
  .copyright_part a {
    color: #7c32ff; }
  @media (max-width: 576px) {
    .copyright_part .footer-social {
      margin-top: 20px; } }
  .copyright_part .footer-social a {
    width: 35px;
    height: 35px;
    display: inline-block;
    line-height: 35px;
    border: 1px solid #ff7e5f;
    text-align: center;
    margin-left: 10px;
    color: #fff; }
    .copyright_part .footer-social a:hover {
      background-color: #ff7e5f !important;
      color: #fff !important; }
    .copyright_part .footer-social a i:hover {
      color: #fff; }
  @media (max-width: 576px) {
    .copyright_part .footer-text {
      text-align: center; } }
  @media only screen and (min-width: 576px) and (max-width: 767px) {
    .copyright_part .footer-text {
      text-align: center;
      margin-bottom: 25px !important; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .copyright_part .footer-text {
      text-align: center;
      margin-bottom: 25px !important; } }
  .copyright_part span.ti-heart {
    font-size: 12px;
    margin: 0px 2px; }

/*=================== contact banner start ====================*/
.contact-info {
  margin-bottom: 25px; }
  .contact-info__icon {
    margin-right: 20px; }
    .contact-info__icon i, .contact-info__icon span {
      color: #8f9195;
      font-size: 27px; }
  .contact-info .media-body h3 {
    font-size: 16px;
    margin-bottom: 0;
    font-size: 16px;
    color: #2a2a2a; }
    .contact-info .media-body h3 a:hover {
      color: #7c32ff; }
  .contact-info .media-body p {
    color: #8a8a8a; }

/*=================== contact banner end ====================*/
/*=================== contact form start ====================*/
.contact-title {
  font-size: 27px;
  font-weight: 600;
  margin-bottom: 20px; }

.form-contact label {
  font-size: 14px; }

.form-contact .form-group {
  margin-bottom: 30px; }
  .form-contact .form-group .button-contactForm {
    padding: 5px 5px 5px 20px; }
  .form-contact .form-group .btn_1 {
    margin-bottom: -30px;
    border: 1px solid #999999;
    border-radius: 0;
    color: #000;
    display: flex;
    align-items: center; }
    @media (max-width: 991px) {
      .form-contact .form-group .btn_1 {
        margin-bottom: 20px; } }
    .form-contact .form-group .btn_1 i {
      color: #fff;
      width: 50px;
      height: 50px;
      background-color: #ff4800;
      display: inline-block;
      line-height: 50px;
      margin-left: 20px;
      background-size: 200% auto;
      -webkit-transition: 0.5s;
      transition: 0.5s; }
    .form-contact .form-group .btn_1:hover {
      border: 1px solid #7c32ff;
      color: #7c32ff !important; }
      .form-contact .form-group .btn_1:hover i {
        color: #7c32ff;
        color: #fff !important; }

.form-contact .form-control {
  border: 1px solid #f0e9ff;
  border-radius: 5px;
  height: 48px;
  padding-left: 18px;
  font-size: 13px;
  background: transparent; }
  .form-contact .form-control:focus {
    outline: 0;
    box-shadow: none; }
  .form-contact .form-control::placeholder {
    font-weight: 300;
    color: #999999; }

.form-contact textarea {
  border-radius: 12px;
  height: 100% !important; }

@media (max-width: 576px) {
  .contact-section .btn_1 {
    margin-bottom: 0px !important;
    margin-top: 0px !important; } }

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .contact-section .btn_1 {
    margin-bottom: 0px !important;
    margin-top: 0px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .contact-section .btn_1 {
    margin-bottom: 0px !important;
    margin-top: 0px !important; } }

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .contact-section .btn_1 {
    margin-bottom: 0px !important;
    margin-top: 0px !important; } }

/*=================== contact form end ====================*/
/* Contact Success and error Area css
============================================================================================ */
.modal-message .modal-dialog {
  position: absolute;
  top: 36%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%) !important;
  margin: 0px;
  max-width: 500px;
  width: 100%; }
  .modal-message .modal-dialog .modal-content .modal-header {
    text-align: center;
    display: block;
    border-bottom: none;
    padding-top: 50px;
    padding-bottom: 50px; }
    .modal-message .modal-dialog .modal-content .modal-header .close {
      position: absolute;
      right: -15px;
      top: -15px;
      padding: 0px;
      color: #fff;
      opacity: 1;
      cursor: pointer; }
    .modal-message .modal-dialog .modal-content .modal-header h2 {
      display: block;
      text-align: center;
      padding-bottom: 10px; }
    .modal-message .modal-dialog .modal-content .modal-header p {
      display: block; }

.breadcrumb {
  position: relative;
  z-index: 1;
  text-align: center;
  background-color: #3b13b0; }
  .breadcrumb .breadcrumb_iner {
    height: 475px;
    width: 100%;
    display: table; }
    .breadcrumb .breadcrumb_iner .breadcrumb_iner_item {
      display: table-cell;
      vertical-align: middle; }
      .breadcrumb .breadcrumb_iner .breadcrumb_iner_item h2 {
        color: #fff;
        font-size: 48px;
        font-weight: 600;
        margin-bottom: 10px;
        text-transform: capitalize; }
        @media (max-width: 576px) {
          .breadcrumb .breadcrumb_iner .breadcrumb_iner_item h2 {
            font-size: 35px; } }
        @media only screen and (min-width: 576px) and (max-width: 767px) {
          .breadcrumb .breadcrumb_iner .breadcrumb_iner_item h2 {
            font-size: 35px; } }
        @media only screen and (min-width: 768px) and (max-width: 991px) {
          .breadcrumb .breadcrumb_iner .breadcrumb_iner_item h2 {
            font-size: 40px; } }
        @media only screen and (min-width: 992px) and (max-width: 1200px) {
          .breadcrumb .breadcrumb_iner .breadcrumb_iner_item h2 {
            font-size: 50px; } }
      .breadcrumb .breadcrumb_iner .breadcrumb_iner_item h5 {
        font-size: 15px;
        font-weight: 500;
        text-transform: uppercase;
        color: #fff;
        margin-top: 23px; }
        .breadcrumb .breadcrumb_iner .breadcrumb_iner_item h5 span {
          font-size: 15px;
          position: relative;
          z-index: 1;
          margin: 0 18px; }
          .breadcrumb .breadcrumb_iner .breadcrumb_iner_item h5 span:after {
            position: absolute;
            content: "";
            width: 8px;
            height: 8px;
            left: -4px;
            top: 6px;
            background-color: #fff;
            border-radius: 50%; }
      .breadcrumb .breadcrumb_iner .breadcrumb_iner_item span {
        margin: 0px 5px;
        font-size: 12px; }

.breadcrumb {
  margin-bottom: 0px !important; }

/**********************footer part css*******************/
.footer_part {
  background-image: url("https://i.ibb.co/THXWPMd/footer-bg.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 130px 0px 25px; }
  @media (max-width: 991px) {
    .footer_part {
      padding: 70px 0px 25px;
      background-image: url("none");
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      background-color: #f7f7f7; } }
  .footer_part img {
    margin-bottom: 35px; }
  @media (max-width: 576px) {
    .footer_part .single_footer_part {
      margin-bottom: 20px; } }
  @media only screen and (min-width: 576px) and (max-width: 767px) {
    .footer_part .single_footer_part {
      margin-bottom: 20px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .footer_part .single_footer_part {
      margin-bottom: 20px; } }
  .footer_part .single_footer_part h4 {
    font-size: 20px;
    margin-bottom: 30px;
    font-weight: 500; }
    @media (max-width: 576px) {
      .footer_part .single_footer_part h4 {
        margin-bottom: 15px; } }
    @media only screen and (min-width: 576px) and (max-width: 767px) {
      .footer_part .single_footer_part h4 {
        margin-bottom: 15px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .footer_part .single_footer_part h4 {
        margin-bottom: 15px; } }
  .footer_part .single_footer_part p {
    margin-bottom: 10px; }
  .footer_part .single_footer_part .list-unstyled li a {
    color: #888888;
    margin-bottom: 10px;
    display: inline-block; }
    .footer_part .single_footer_part .list-unstyled li a:hover {
      color: #ff4800 !important; }
  .footer_part .single_footer_part .social_icon {
    float: left;
    margin-top: 38px; }
    @media (max-width: 991px) {
      .footer_part .single_footer_part .social_icon {
        margin-top: 15px; } }
    .footer_part .single_footer_part .social_icon li a {
      margin-left: 0;
      margin-right: 25px; }
  .footer_part .single_footer_part .mail_part {
    position: relative; }
    .footer_part .single_footer_part .mail_part input {
      padding: 9px 22px;
      font-size: 13px;
      border-radius: 50px;
      border: 2px solid #fff;
      margin-top: 20px;
      width: 100%;
      background-color: #f9f9fe;
      border: 1px solid #ebebeb; }
    .footer_part .single_footer_part .mail_part .email_icon {
      position: absolute;
      right: 0px;
      top: 20px;
      width: 60px;
      height: 39px;
      background-color: #7c32ff;
      text-align: center;
      color: #fff;
      border-radius: 50px;
      line-height: 10px;
      border: 2px solid #7c32ff; }
  .footer_part hr {
    background-color: #cad7dc;
    margin-top: 50px;
    margin-bottom: 25px; }
  .footer_part .footer_icon {
    text-align: right; }
    .footer_part .footer_icon li {
      display: inline-block; }
      .footer_part .footer_icon li a {
        font-size: 14px;
        color: #888888;
        margin-left: 30px;
        -webkit-transition: 0.5s;
        transition: 0.5s; }
        @media (max-width: 576px) {
          .footer_part .footer_icon li a {
            margin-left: 0px;
            margin-right: 25px; } }
        @media only screen and (min-width: 576px) and (max-width: 767px) {
          .footer_part .footer_icon li a {
            margin-left: 0px;
            margin-right: 25px; } }
        .footer_part .footer_icon li a :hover {
          color: #ff4800; }
    @media (max-width: 576px) {
      .footer_part .footer_icon {
        float: left;
        margin-top: 15px; } }
  .footer_part .copyright_text p {
    color: #888; }
  .footer_part .copyright_text span {
    color: #ff4800;
    font-size: 12px; }
  @media (max-width: 991px) {
    .footer_part .copyright_text {
      text-align: center !important; } }
  .footer_part .copyright_text p a {
    color: #ff4800;
    -webkit-transition: 0.5s;
    transition: 0.5s; }
    .footer_part .copyright_text p a:hover {
      color: #ff4800; }
  .footer_part .info {
    color: red !important; }
  @media (max-width: 991px) {
    .footer_part .footer_icon {
      text-align: center;
      float: none; } }
